export const stateResidencyPromptOptions = [
  "I no longer wish to receive the messages",
  "I did not apply for a loan",
  "Messages are spam and should be reported",
  "Other"
];

export const stateOptions = [
  { value: "AG", label: "An Giang" },
  { value: "BR", label: "Bà Rịa - Vũng Tàu" },
  { value: "BG", label: "Bắc Giang" },
  { value: "BK", label: "Bắc Kạn" },
  { value: "BL", label: "Bạc Liêu" },
  { value: "BN", label: "Bắc Ninh" },
  { value: "BT", label: "Bến Tre" },
  { value: "BD", label: "Bình Định" },
  { value: "BDu", label: "Bình Dương" },
  { value: "BP", label: "Bình Phước" },
  { value: "BT", label: "Bình Thuận" },
  { value: "CM", label: "Cà Mau" },
  { value: "CB", label: "Cao Bằng" },
  { value: "CT", label: "Cần Thơ" },
  { value: "ĐNa", label: "Đà Nẵng" },
  { value: "ĐL", label: "Đắk Lắk" },
  { value: "ĐN", label: "Đắk Nông" },
  { value: "ĐB", label: "Điện Biên" },
  { value: "ĐN", label: "Đồng Nai" },
  { value: "ĐT", label: "Đồng Tháp" },
  { value: "GL", label: "Gia Lai" },
  { value: "HG", label: "Hà Giang" },
  { value: "HN", label: "Hà Nam" },
  { value: "HT", label: "Hà Tĩnh" },
  { value: "HD", label: "Hải Dương" },
  { value: "HP", label: "Hải Phòng" },
  { value: "HG", label: "Hậu Giang" },
  { value: "HN", label: "Hà Nội" },
  { value: "HB", label: "Hòa Bình" },
  { value: "H", label: "Hồ Chí Minh" },
  { value: "HĐ", label: "Hưng Yên" },
  { value: "KH", label: "Khánh Hòa" },
  { value: "KG", label: "Kiên Giang" },
  { value: "KT", label: "Kon Tum" },
  { value: "LC", label: "Lai Châu" },
  { value: "LS", label: "Lạng Sơn" },
  { value: "LĐ", label: "Lâm Đồng" },
  { value: "LA", label: "Long An" },
  { value: "ND", label: "Nam Định" },
  { value: "NA", label: "Nghệ An" },
  { value: "NB", label: "Ninh Bình" },
  { value: "NT", label: "Ninh Thuận" },
  { value: "PT", label: "Phú Thọ" },
  { value: "PY", label: "Phú Yên" },
  { value: "QB", label: "Quảng Bình" },
  { value: "QNa", label: "Quảng Nam" },
  { value: "QN", label: "Quảng Ngãi" },
  { value: "QNi", label: "Quảng Ninh" },
  { value: "QT", label: "Quảng Trị" },
  { value: "ST", label: "Sóc Trăng" },
  { value: "SL", label: "Sơn La" },
  { value: "TNN", label: "Tây Ninh" },
  { value: "TB", label: "Thái Bình" },
  { value: "TN", label: "Thái Nguyên" },
  { value: "TH", label: "Thanh Hóa" },
  { value: "TT", label: "Thừa Thiên Huế" },
  { value: "TG", label: "Tiền Giang" },
  { value: "TV", label: "Trà Vinh" },
  { value: "TQ", label: "Tuyên Quang" },
  { value: "VL", label: "Vĩnh Long" },
  { value: "VP", label: "Vĩnh Phúc" },
  { value: "YB", label: "Yên Bái" }
];
