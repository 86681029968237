import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Disclaimer.scss";
import { Link } from "react-router-dom";

const Disclaimer = () => {
  return (
    <div className="Disclaimer">
      <Navbar />

      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Tuyên bố miễn trừ trách nhiệm</h2>

          <p>
            <b>
              <Link to="/">Seekerloans.com</Link>
            </b>{" "}
            Tuyên bố miễn trừ trách nhiệm Seekerloans.com không phải là người
            cho vay, hay đại diện, môi giới, đại lý của bất kỳ người cho vay nào
            và chúng tôi không đưa ra bất kỳ quyết định cho vay hoặc tín dụng
            nào. Trang web này không phải là một đề nghị hoặc chào mời cho vay.
            Vai trò của trang web này là gửi thông tin bạn cung cấp cho người
            cho vay, người có toàn quyền xem xét và phê duyệt khoản vay. Bằng
            cách gửi thông tin của mình, bạn đồng ý cho phép những người cho vay
            tham gia xác minh thông tin của bạn và kiểm tra tín dụng của bạn.
            Không phải tất cả người cho vay đều có thể cung cấp số tiền tối đa
            được quảng cáo. Thời gian chuyển tiền có thể khác nhau giữa những
            người cho vay và có thể phụ thuộc vào tổ chức tài chính cá nhân của
            bạn. Trong một số trường hợp có thể fax được yêu cầu. Không phải tất
            cả những người cho vay trong hệ thống của chúng tôi đều hoạt động ở
            tất cả các tiểu bang của Hoa Kỳ và các tiểu bang được Trang web này
            phục vụ có thể thay đổi theo thời gian mà không cần thông báo. Nếu
            bạn có bất kỳ câu hỏi nào về khoản vay của mình, vui lòng liên hệ
            trực tiếp với người cho vay của bạn. Các khoản vay tiền mặt ngắn hạn
            nhằm giải quyết nhu cầu tiền mặt trước mắt và không phải là giải
            pháp lâu dài cho các vấn đề tài chính. Cư dân của một số tiểu bang
            có thể không đủ điều kiện cho khoản vay tiền mặt ngắn hạn dựa trên
            yêu cầu của người cho vay.
          </p>

          <p>
            Seekerloans.com là một nền tảng miễn phí thu thập thông tin cá nhân
            của bạn để gửi cho người cho vay xem xét các yêu cầu vay tiền của
            bạn. Chúng tôi không liên quan đến quá trình xem xét hoặc giải quyết
            các câu hỏi liên quan đến khoản vay của bạn, vui lòng liên hệ trực
            tiếp với nhà cung cấp tài chính của bạn để làm rõ tất cả thông tin.
            Chúng tôi cố gắng hết sức để kết nối bạn với người cho vay phù hợp
            nhất, tuy nhiên quyết định phê duyệt sẽ hoàn toàn do người cho vay
            quyết định.
          </p>
          <p>
            Bằng cách đọc Chính sách quyền riêng tư và Điều khoản sử dụng, bạn
            hiểu và đồng ý rằng Trang web này thu thập thông tin cá nhân và chia
            sẻ với các đối tác bên thứ ba. Ngoài ra, các liên kết của các trang
            web của bên thứ ba cũng được trình bày trên Trang web này. Vì bất kỳ
            lý do vay vốn nào, bạn nên kết nối với chuyên gia tư vấn trước khi
            quyết định khoản vay.
          </p>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Disclaimer;
