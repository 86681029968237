const { publicRequest } = require("../requestMethods");

module.exports = {
  trimWhiteSpaceObjValue(obj = {}) {
    for (let key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      }
    }
  },
  async getYourIpAddress() {
    const yourIp = await publicRequest.get("/your-ip");
    return yourIp?.data?.ip || "";
  },
  validateAmout(amount) {
    const cleanedString = amount.replace(/\$|,/g, "");
    const numericValue = parseFloat(cleanedString);

    switch (true) {
      case numericValue < 100:
        return "Số tiền vay phải lớn hơn 100 USD";
      case numericValue > 5000:
        return "Bạn chỉ có thể vay tối đa 5.000 USD";
      case numericValue % 100 !== 0:
        return "Chỉ nhập bội số của 100, ví dụ: 100, 200, 300, ...";
      default:
        return true;
    }
  },
  autoCompleteEmailDomain(newInputValue, emailDomains) {
    const [email, domain] = newInputValue.split("@");
    return emailDomains
      .filter((option) => option.includes(domain))
      .map((option) => `${email}@${option}`);
  },
  withValueLimit(values) {
    const { formattedValue, floatValue } = values;
    if (formattedValue === "") return true;
    if (formattedValue === "$0") return false;
    if (formattedValue === "-$0") return false;
    return !isNaN(floatValue) && floatValue >= 0 && floatValue <= 5000;
  },
  async handleAutocompleteField(value, timeoutRef, searchApiFun = null) {
    return new Promise((resolve, reject) => {
      let results = [];
      timeoutRef.current && clearTimeout(timeoutRef.current);

      const isValidInput = value.length > 0 && value.length < 50;
      if (!isValidInput) {
        resolve([]);
        return;
      }

      timeoutRef.current = setTimeout(async () => {
        try {
          const res = await searchApiFun(value);
          results = res;
          resolve(results);
        } catch (error) {
          reject(error);
        }
      }, 300);
    });
  }
};
