import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./TermsOfUse.scss";

const TermsOfUse = () => {
  return (
    <div className="TermsOfUse">
      <Navbar />
      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Điều khoản sử dụng</h2>

          <h4>Điều khoản sử dụng của chúng tôi</h4>
          <p>
            Seekerloans.com (“chúng tôi” hoặc “của chúng tôi”) sở hữu và vận
            hành trang web có trụ sở tại Seekerloans.com, (“Trang web”).
            Seekerloans.com cung cấp và duy trì Trang web này để lấy thông tin
            của bạn, với điều kiện là bạn chấp nhận các điều khoản, điều kiện và
            thông báo trong thỏa thuận này (“Điều khoản sử dụng”). Bằng cách
            truy cập và sử dụng Trang web, bạn chấp nhận và đồng ý, không giới
            hạn, những điều sau:
          </p>

          <h4>1. Cách sử dụng trang web</h4>
          <p>
            Seekerloans.com được phát triển nhằm mang đến cho bạn cơ hội kết nối
            với những người cho vay đáng tin cậy. Trang web này dành cho những
            người trên 18 tuổi và cư dân hợp pháp tại Hoa Kỳ. Vai trò của chúng
            tôi không phải là người cho vay, chúng tôi chỉ cung cấp một nơi miễn
            phí để giúp bạn đăng ký vốn và kết nối với người cho vay để giải
            quyết yêu cầu tài chính của bạn. Chúng tôi không cho vay tiền và
            không đóng vai trò gì trong quá trình liên lạc của cả hai bên và
            chúng tôi không đại diện hay quảng bá dịch vụ của bất kỳ người cho
            vay cụ thể nào. Bạn không có nghĩa vụ phải sử dụng trang web của
            chúng tôi, cung cấp thông tin cá nhân của mình hoặc chấp nhận lời đề
            nghị từ người cho vay bên thứ ba.
          </p>
          <p>
            Đối với bất kỳ câu hỏi nào liên quan đến nguồn vốn, bạn nên liên hệ
            trực tiếp với người cho vay để giải quyết vì chúng tôi không tham
            gia vào quy trình cho vay. Do đó, chúng tôi sẽ không chịu trách
            nhiệm và nghĩa vụ pháp lý đối với bất kỳ sự hiểu lầm, phí, thiệt hại
            nào xảy ra trong quá trình liên lạc giữa bạn và người cho vay.
          </p>
          <p>
            Khi bạn truy cập và tham gia trang web, bạn thừa nhận rằng bạn biết
            Điều khoản sử dụng của chúng tôi. Bạn hiểu và đồng ý rằng một số
            thông tin cá nhân sẽ được yêu cầu chia sẻ nhằm mục đích kết nối với
            người cho vay. Bạn biết rằng chúng tôi có thể chia sẻ thông tin này
            với họ và đến lượt họ, họ cũng có thể sử dụng thông tin đó. Như đã
            nêu rõ trong Chính sách quyền riêng tư của chúng tôi, bạn đồng ý và
            chấp thuận tất cả các phần được đề cập trong Chính sách quyền riêng
            tư của chúng tôi.{" "}
          </p>
          <p>
            Xin lưu ý rằng việc xem xét đơn đăng ký là một quy trình mà người
            cho vay sẽ thực hiện để xác minh thông tin cá nhân của bạn nhằm xem
            bạn có đủ điều kiện hay không. Điều này có thể ảnh hưởng đến điểm
            tín dụng của bạn khi họ gửi yêu cầu đó đến cơ sở dữ liệu quốc gia và
            cơ quan báo cáo tín dụng.
          </p>

          <h4>2. Sản phẩm/Dịch vụ</h4>
          <p>
            Bằng cách truy cập trang web và cung cấp thông tin của mình, bạn
            hiểu và đồng ý rằng Seekerloans.com không hoạt động với tư cách là
            người cho vay, không yêu cầu mua sản phẩm hoặc phát hành bất kỳ tính
            năng nào của sản phẩm hoặc dịch vụ trên trang web. Nếu có bất kỳ sản
            phẩm/dịch vụ nào được quảng cáo bởi các đối tác của chúng tôi sau
            khi rời khỏi trang web của chúng tôi, trách nhiệm đối với những sản
            phẩm/dịch vụ đó sẽ chỉ thuộc về các nhà cung cấp.{" "}
          </p>

          <h4>3. Quyền sở hữu trí tuệ</h4>
          <p>
            Tất cả các tài sản bao gồm nhãn hiệu, tiêu đề, ký tự, thiết kế, đồ
            họa, nội dung, logo và các tài sản khác đều thuộc sở hữu của
            Seekerloans.com và được bảo vệ bởi luật bản quyền của Hoa Kỳ. Bạn
            không được phép sao chép, sao chép…tài sản trí tuệ của chúng tôi mà
            không có sự cho phép trước bằng văn bản của chúng tôi
          </p>

          <h4>4. Giới hạn trách nhiệm pháp lý</h4>
          <p>
            Khi đồng ý tham gia trang web, bạn tự nguyện cung cấp thông tin cá
            nhân của mình và hiểu tất cả nội dung, dịch vụ do trang web cung
            cấp, công ty (trang web) không chịu bất kỳ trách nhiệm nào đối với
            bất kỳ hình thức trực tiếp, gián tiếp, ngẫu nhiên, do hậu quả hoặc
            hình thức nào khác về mất mát hoặc thiệt hại, bao gồm nhưng không
            giới hạn việc mất lợi nhuận, dữ liệu, cơ hội, chi phí, thương tích
            cá nhân/tử vong bất đáng và các hình thức thiệt hại khác mà bạn có
            thể gặp phải do việc sử dụng trang web.
          </p>
          <p>
            Một số khu vực pháp lý không công nhận hoặc cho phép loại trừ các
            bảo đảm cụ thể nhất định như thiệt hại ngẫu nhiên hoặc do hậu quả.
            Trong những trường hợp như vậy, toàn bộ trách nhiệm pháp lý của công
            ty trong trường hợp mất mát hoặc thiệt hại liên quan đến việc sử
            dụng trang web được giới hạn trong phạm vi tổng số tiền bạn phải trả
            cho công ty liên quan đến vấn đề tranh chấp cụ thể. .
          </p>

          <h4>5. Tuân thủ pháp luật</h4>
          <p>
            Bạn đồng ý tuân thủ tất cả các luật, quy chế, pháp lệnh và quy định
            hiện hành liên quan đến việc bạn sử dụng Trang web cũng như việc bạn
            chấp nhận hoặc mua sản phẩm hoặc dịch vụ thông qua các nhà cung cấp
            bên thứ ba của Trang web này.
          </p>

          <h4>6. Đồng ý điện tử</h4>
          <p>
            Đồng ý điện tử là chữ ký điện tử của bạn. Bằng cách nhấp vào các
            liên kết như “Yêu cầu ngay”, “Tôi đồng ý”, “Gửi”, “Ký điện tử”,
            “Đồng ý”, v.v., bạn đồng ý cung cấp chữ ký điện tử của mình, nghĩa
            là bạn cũng đồng ý rằng bạn đồng ý liên lạc với chúng tôi và các bên
            thứ ba bằng phương thức điện tử qua điện thoại, email, tin nhắn,...
          </p>

          <h4>7. Vi phạm</h4>
          <p>
            Bất kỳ hành động nào chống lại Điều khoản sử dụng của chúng tôi đều
            bị coi là vi phạm, trong những trường hợp này, chúng tôi có quyền
            chấm dứt tài khoản của bạn mà không cần thông báo và xóa tất cả tài
            liệu bạn cung cấp khỏi trang web của chúng tôi.{" "}
          </p>

          <h4>8. Sửa đổi và cập nhật</h4>
          <p>
            Vì những mục đích quan trọng nhằm đảm bảo bạn hiểu rõ về tất cả các
            điều khoản, vui lòng đọc kỹ tất cả thông tin và thường xuyên xem lại
            để cập nhật một số sửa đổi mới vì chúng tôi có quyền sửa đổi và cập
            nhật Điều khoản sử dụng hiện tại mà không cần thông báo trước.
          </p>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default TermsOfUse;
