export const options = [
  {
    label: "Tiền lương",
    value: "EMPLOYMENT"
  },
  {
    label: "Tự kinh doanh",
    value: "SELF_EMPLOYMENT"
  },
  {
    label: "Quân đội",
    value: "MILITARY"
  },
  {
    label: "Benefits",
    value: "BENEFITS"
  }
];
