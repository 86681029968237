import React from "react";
import { Link } from "react-router-dom";
import prev from "../../../assets/images/prev.png";

import "../Button.scss";

export const PrevBtn = ({ to }) => {
  return (
    <Link to={to} className="btn prev-btn hover-red">
      <img src={prev} alt="" />
    </Link>
  );
};
