import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./DoNotSellInformation.scss";
import { stateResidencyPromptOptions, stateOptions } from "./options";

import { useForm, Controller } from "react-hook-form";

import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// CUSTOM STYLE
import { makeStyles } from "@material-ui/core/styles";
import { publicRequest } from "../../../requestMethods";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  formControl: {
    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
      color: "#A6A7A8",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    }
  },
  select: {
    marginBottom: "20px",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#33A8FC"
      },
      "&:hover fieldset": {
        borderColor: "#33A8FC"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#33A8FC"
      }
    }
  },
  label: {
    background: "#fff !important",
    paddingRight: "3px !important",
    color: "#A6A7A8 !important",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    "&.Label.Mui-focused": {
      color: "#33A8FC"
    }
  },
  menuItem: {
    "&.MuiButtonBase-root": {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      justifyContent: "center"
    },
    "&.Mui-selected": {
      backgroundColor: "#33A8FC !important",
      color: "#fff"
    },
    "&.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover": {
      backgroundColor: "#33A8FC !important",
      color: "#fff"
    },
    "&.MuiOutlinedInput-notchedOutline": {
      borderColor: "#33A8FC !important",
      color: "#fff"
    }
  },
  textField: {
    marginBottom: "20px !important",
    "& .MuiInputBase-input": {
      color: "#000 !important",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    },
    "& .MuiFormLabel-root": {
      backgroundColor: "#fff",
      paddingRight: "3px",
      color: "#A6A7A8 !important",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    },

    "& .Label.Mui-focused": {
      color: "#33A8FC !important"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#33A8FC"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#33A8FC"
      },
      "&:hover fieldset": {
        borderColor: "#33A8FC !important"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#33A8FC !important"
      }
    }
  },
  // RESPONSIVE
  "@media (max-width: 768px)": {
    formControl: {
      marginBottom: "15px",
      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
        fontSize: "14px"
      }
    },
    select: {
      "&.MuiOutlinedInput-root": {
        fontSize: "14px"
      }
    },
    menuItem: {
      "&.MuiButtonBase-root": {
        fontSize: "14px"
      }
    },
    textField: {
      marginBottom: "15px !important",
      "& .MuiInputBase-input": {
        fontSize: "14px"
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px"
      }
    },
    label: {
      fontSize: "14px !important",
      padding: "0 5px !important"
    }
  }
});

const DoNotSellInformation = () => {
  // const navigate = useNavigate();
  const classes = useStyles();
  const windowWidth = window.innerWidth;
  const [loadingButton, setLoadingButton] = useState(false);
  const [stateResidencyPrompt, setStateResidencyPrompt] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  // STYLE
  const btnSubmitStyle = {
    marginBottom: windowWidth > 756 ? "20px" : 0,
    width: "100%",
    height: windowWidth > 756 ? "50px" : "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "5px",
    background: "#F4C811",

    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: windowWidth > 756 ? "25px" : "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase"
  };

  const handleCheckZipCode = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    setZipCode(value);
  };

  const handleCheckPhoneNumber = (e) => {
    let value = e.target.value;

    // Xóa tất cả các ký tự không phải số khỏi chuỗi
    value = value.replace(/\D/g, "");

    // Áp dụng định dạng (123)-123-1234
    if (value.length > 0) {
      if (value.length <= 3) {
        value = `(${value}`;
      } else if (value.length <= 6) {
        value = `(${value.slice(0, 3)})-${value.slice(3)}`;
      } else {
        value = `(${value.slice(0, 3)})-${value.slice(3, 6)}-${value.slice(
          6,
          10
        )}`;
      }
    }

    setPhone(value);
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    const res = await publicRequest.post(`do-not-sell-information`, data);
    // const res = await updateContact(dispath, contactRedux._id, data);
    if (res?.data?.status === 200) {
      Swal.fire({
        title: res?.data?.message,
        icon: "success",
        confirmButtonText: "OK"
      });
    } else {
      Swal.fire({
        title: res?.data?.message,
        icon: "error",
        confirmButtonText: "OK"
      });
    }
    setLoadingButton(false);
  };

  return (
    <div className="DoNotSellInformation">
      <Navbar />

      <div className="container-privacy">
        <form className="box-privacy" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Không bán thông tin của tôi</h2>
          <p className="text">
            Biểu mẫu này cho phép cư dân California, Nevada, Virginia, Utah và
            Connecticut gửi một số yêu cầu về quyền dữ liệu nhất định qua liên
            hệ với chúng tôi. Vui lòng sử dụng biểu mẫu này nếu bạn là cư dân
            California, Nevada, Virginia, Utah hoặc Connecticut yêu cầu thêm
            thông tin liên quan đến quyền của bạn.
          </p>
          <p className="text">
            Đối với bất kỳ câu hỏi hoặc yêu cầu quyền bổ sung nào, vui lòng gửi
            email cho chúng tôi theo địa chỉ{" "}
            <b>
              <Link target="_blank" to="mailto:contact@seekerloans.com">
                contact@seekerloans.com
              </Link>
            </b>{" "}
            Nếu bạn là Cư dân California, Nevada, Virginia, Utah hoặc
            Connecticut, vui lòng chọn một yêu cầu:
          </p>
          <div className="select-field">
            <label className="label-ct">
              If you are a California, Nevada, Virginia, Utah or Connecticut
              Resident, please select a request:
            </label>
            {/* stateResidencyPrompt */}
            <Controller
              name="stateResidencyPrompt"
              defaultValue={stateResidencyPrompt}
              control={control}
              rules={{ required: "Please select an answer!" }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  error={!!errors?.stateResidencyPrompt}
                  className={classes.formControl}
                  size={windowWidth > 768 ? "medium" : "small"}
                >
                  {!stateResidencyPrompt && (
                    <InputLabel className={classes.label}>
                      Chọn một
                    </InputLabel>
                  )}
                  <Select
                    className={classes.select}
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value);
                      setStateResidencyPrompt(value);
                    }}
                    value={stateResidencyPrompt}
                    displayEmpty
                  >
                    {/* <MenuItem disabled value="">
                      Select one
                    </MenuItem> */}
                    {stateResidencyPromptOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option}
                        className={classes.menuItem}
                        style={{
                          minHeight: windowWidth > 768 ? "38px" : "36px",
                          fontSize: windowWidth > 768 ? "16px" : "14px",
                          border: "0.01px solid rgb(5 4 4 / 8%)"
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      marginTop: "-20px",
                      marginBottom: "20px"
                    }}
                  >
                    {errors?.stateResidencyPrompt &&
                      errors?.stateResidencyPrompt.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
          {/* end stateResidencyPrompt */}
          {/* firstName */}
          <div className="input-field">
            <label className="label-ct">Tên</label>
            <Controller
              name="firstName"
              control={control}
              defaultValue={firstName}
              rules={{
                required: "Vui lòng nhập tên!"
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="firstName"
                  autoComplete="firstName"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.firstName}
                  emptylabel=""
                  placeholder=""
                  helperText={errors?.firstName && errors?.firstName?.message}
                  inputProps={{
                    onInput: (e) => {
                      setFirstName(e.target.value);
                    }
                  }}
                  value={firstName}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end firstName */}
          {/* middleName */}
          <div className="input-field">
            <label className="label-ct">Tên đệm</label>
            <Controller
              name="middleName"
              control={control}
              defaultValue={middleName}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="middleName"
                  autoComplete="middleName"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.middleName}
                  emptylabel="Optional"
                  placeholder="Optional"
                  helperText={errors?.middleName && errors?.middleName?.message}
                  inputProps={{
                    onInput: (e) => {
                      setMiddleName(e.target.value);
                    }
                  }}
                  value={middleName}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end middleName */}
          {/* Last Name */}
          <div className="input-field">
            <label className="label-ct">Họ</label>
            <Controller
              name="lastName"
              control={control}
              defaultValue={lastName}
              rules={{
                required: "Vui lòng nhập họ!"
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="lastName"
                  autoComplete="lastName"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.lastName}
                  emptylabel=""
                  placeholder=""
                  helperText={errors?.lastName && errors?.lastName?.message}
                  inputProps={{
                    onInput: (e) => {
                      setLastName(e.target.value);
                    }
                  }}
                  value={lastName}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Last Name */}
          {/* Email address */}
          <div className="input-field">
            <label className="label-ct">Địa chỉ email</label>
            <Controller
              name="email"
              control={control}
              defaultValue={email}
              rules={{
                required: "Vui lòng nhập địa chỉ email!",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Địa chỉ email không hợp lệ!"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="email"
                  autoComplete="email"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.email}
                  emptylabel=""
                  placeholder=""
                  helperText={errors?.email && errors?.email?.message}
                  inputProps={{
                    onInput: (e) => setEmail(e.target.value)
                  }}
                  value={email}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Email address */}
          {/* Mobile number */}
          <div className="input-field">
            <label className="label-ct">Số điện thoại</label>
            <Controller
              name="phone"
              control={control}
              defaultValue={phone}
              rules={{
                required: "Vui lòng nhập số điện thoại!",
                minLength: {
                  value: 10,
                  message: "Số điện thoại không hợp lệ!"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="phone"
                  autoComplete="phone"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.phone}
                  emptylabel={!phone ? "" : ""}
                  placeholder={!phone ? "" : "Số điện thoại"}
                  helperText={errors?.phone && errors?.phone?.message}
                  inputProps={{
                    maxLength: 14,
                    onInput: handleCheckPhoneNumber
                  }}
                  value={phone}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Mobile number */}
          {/* Country */}
          <div className="input-field">
            <label className="label-ct">Quốc gia</label>
            <Controller
              name="country"
              control={control}
              defaultValue={country}
              rules={{
                required: "Vui lòng nhập quốc gia!"
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="country"
                  autoComplete="country"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.country}
                  emptylabel=""
                  placeholder=""
                  helperText={errors?.country && errors?.country?.message}
                  inputProps={{
                    onInput: (e) => {
                      setCountry(e.target.value);
                    }
                  }}
                  value={country}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end  Country */}
          {/* Zip Code */}
          <div className="input-field">
            <label className="label-ct">Mã bưu chính</label>
            <Controller
              name="zipCode"
              control={control}
              defaultValue={zipCode}
              rules={{
                required: "Vui lòng nhập mã bưu chính!",
                minLength: {
                  value: 5,
                  message: "Mã bưu chính không hợp lệ!"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="zipCode"
                  autoComplete="zipCode"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.zipCode}
                  emptylabel=""
                  placeholder=""
                  helperText={errors?.zipCode && errors?.zipCode?.message}
                  inputProps={{
                    maxLength: 5,
                    onInput: handleCheckZipCode
                  }}
                  value={zipCode}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Zip Code */}
          {/* State */}
          <div className="input-field">
            <label className="label-ct">State</label>
            <Controller
              name="state"
              defaultValue={state}
              control={control}
              rules={{ required: "Vui lòng nhập state!" }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  error={!!errors?.state}
                  className={classes.formControl}
                  size={windowWidth > 768 ? "medium" : "small"}
                >
                  {/* {!state && (
                    <InputLabel className={classes.label}>Florida</InputLabel>
                  )} */}
                  <Select
                    className={classes.select}
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value);
                      setState(value);
                    }}
                    value={state}
                    displayEmpty
                  >
                    {/* <MenuItem disabled value="">
                      Select one
                    </MenuItem> */}
                    {stateOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        className={classes.menuItem}
                        style={{
                          minHeight: windowWidth > 768 ? "38px" : "36px",
                          fontSize: windowWidth > 768 ? "16px" : "14px",
                          border: "0.01px solid rgb(5 4 4 / 8%)"
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      marginTop: "-20px",
                      marginBottom: "20px"
                    }}
                  >
                    {errors?.state && errors?.state.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
          {/* end State */}
          {/* Address */}
          <div className="input-field">
            <label className="label-ct">Địa chỉ</label>
            <Controller
              name="address"
              control={control}
              defaultValue={address}
              rules={{
                required: "Vui lòng nhập địa chỉ!",
                minLength: {
                  value: 5,
                  message: "Độ dài phải lớn hơn 5 ký tự!"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="address"
                  autoComplete="address"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.address}
                  emptylabel=""
                  placeholder=""
                  helperText={errors?.address && errors?.address?.message}
                  inputProps={{
                    onInput: (e) => {
                      setAddress(e.target.value);
                    }
                  }}
                  value={address}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Address */}
          {/* City */}
          <div className="input-field">
            <label className="label-ct">Thành phố</label>
            <Controller
              name="city"
              control={control}
              defaultValue={city}
              rules={{
                required: "Vui lòng nhập thành phố!",
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="city"
                  autoComplete="city"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.city}
                  emptylabel=""
                  placeholder=""
                  helperText={errors?.city && errors?.city?.message}
                  inputProps={{
                    onInput: (e) => {
                      setCity(e.target.value);
                    }
                  }}
                  value={city}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end City */}
          <LoadingButton
            endIcon={<SendIcon />}
            loading={loadingButton}
            loadingPosition="end"
            variant="contained"
            fullWidth
            size="large"
            style={btnSubmitStyle}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
            type="submit"
          >
            <span>Bắt đầu ngay</span>
          </LoadingButton>
        </form>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default DoNotSellInformation;
