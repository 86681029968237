export const loanReasonOptions = [
  {
    label: "Mua ô tô",
    value: "AUTO_PURCHASE"
  },
  {
    label: "Sửa chữa ô tô",
    value: "AUTO_REPAIR"
  },
  {
    label: "Sửa chữa nhà cửa",
    value: "HOME_IMPROVEMENT"
  },
  {
    label: "Hợp nhất nợ",
    value: "DEBT_CONSOLIDATION"
  },
  {
    label: "Thanh toán nợ",
    value: "DEBT_SETTLEMENT"
  },
  {
    label: "Hợp nhất thẻ tín dụng",
    value: "CREDIT_CARD_CONSOLIDATION"
  },
  {
    label: "Doanh nghiệp nhỏ",
    value: "BUSINESS"
  },
  {
    label: "Thuê hoặc thế chấp",
    value: "RENT_OR_MORTGAGE"
  },
  {
    label: "Chi phí y tế",
    value: "MEDICAL"
  },
  {
    label: "Di chuyển",
    value: "MOVING"
  },
  {
    label: "Tình huống khẩn cấp",
    value: "EMERGENCY_SITUATION"
  },
  {
    label: "Giáo dục",
    value: "EDUCATION"
  },
  {
    label: "Năng lượng tái tạo",
    value: "RENEWABLE_ENGERGY"
  },
  {
    label: "Khoản vay sinh viên",
    value: "STUDENT_LOAN_REFINANCE"
  },
  {
    label: "Thuế",
    value: "TAXES"
  },
  {
    label: "Lý do Khác",
    value: "OTHER"
  }
];
