import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import Footer from "../../../components/Footer/Footer";

import "./Step3.scss";
import Progress from "../../../components/Progress/Progress";

import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import { Controller, useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { FormHelperText, TextField } from "@mui/material";
import InputMask from "react-input-mask";
// CUSTOM STYLE
import { MarketingPartners } from "../../../components/popups/MarketingPartners/MarketingPartners";
import { btnSubmitCustom, labelCustom, textFieldCustom } from "./style";

const Step3 = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();

  const isMobile = window.innerWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const [opentMarketingPartners, setOpentMarketingPartners] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [phone, setPhone] = useState(contactRedux?.phone ?? "");

  // SUBMIT FUNC
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    try {
      setLoadingButton(!loadingButton);

      const res = await updateContact(dispath, contactRedux?._id, data);
      if (res?.status === 200)
        setTimeout(() => {
          setLoadingButton(false);
          navigate("/step4");
        }, 300);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      {opentMarketingPartners && (
        <MarketingPartners
          opentMarketingPartners={opentMarketingPartners}
          setOpentMarketingPartners={setOpentMarketingPartners}
        />
      )}

      <div className="step-3">
        <Navbar />
        <div className="container">
          <Progress step={3} />
        </div>
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="title">
              Xin chào {contactRedux?.firstName},<br /> vui lòng cung cấp số
              điện thoại của bạn?
            </h2>
            <h5>
              Người cho vay có thể gọi cho bạn để thảo luận về các điều khoản
              của bạn thông qua số điện thoại bạn cung cấp
            </h5>
            <div className="form-control">
              <FormHelperText sx={labelCustom}>
                Số điện thoại của bạn
              </FormHelperText>
              <Controller
                name="phone"
                control={control}
                defaultValue={phone}
                rules={{
                  required: "Vui lòng điền số điện thoại!",
                  pattern: {
                    value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/g,
                    message: "Số điện thoại không hợp lệ!"
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputMask {...field} maskChar="_">
                    {() => (
                      <TextField
                        {...field}
                        inputRef={(input) => {
                          input?.addEventListener("focus", () => {
                            input?.setAttribute("inputmode", "numeric");
                          });
                          ref(input);
                        }}
                        fullWidth
                        sx={{ ...textFieldCustom, marginBottom: "13px" }}
                        size={isMobile ? "small" : "medium"}
                        error={!!errors?.phone}
                        helperText={errors?.phone && errors?.phone?.message}
                        placeholder="VD: 0987654321"
                        InputLabelProps={{ shrink: false }}
                        onInput={(e) => setPhone(e.target.value)}
                        // autoComplete="phone"
                      />
                    )}
                  </InputMask>
                )}
              />
            </div>
            <p className="text">
              Bằng cách nhấp vào nút <b>"Bước tiếp theo"</b>, tôi xác minh rằng
              đây là số điện thoại của tôi và qua đây tôi đồng ý rõ ràng để
              Search ROI, LLC, Cash By Phone, LLC và{" "}
              <Link
                onClick={() =>
                  setOpentMarketingPartners(!opentMarketingPartners)
                }
              >
                Đối tác tiếp thị
              </Link>
              , công ty con hoặc đại lý liên hệ theo số điện thoại đó liên quan
              đến yêu cầu vay vốn của tôi và cho các mục đích tiếp thị khác, bao
              gồm liên hệ qua hệ thống quay số tự động, tin nhắn thoại nhân tạo
              hoặc được ghi âm trước hoặc tin nhắn văn bản. Tôi hiểu rằng tôi
              không bắt buộc phải đưa ra sự đồng ý của mình như một điều kiện để
              mua bất kỳ sản phẩm hoặc dịch vụ nào. Tôi hiểu thêm rằng tôi có
              thể rút lại sự đồng ý của mình bất kỳ lúc nào và nhà cung cấp của
              tôi có thể tính phí cho những tin nhắn này. Đối với các chiến dịch
              nhắn tin văn bản, hãy nhắn tin DỪNG để dừng và GIÚP ĐỠ để được trợ
              giúp. Tần số tin nhắn thay đổi. Tốc độ tin nhắn và dữ liệu có thể
              được áp dụng.
            </p>
            <div className="group-btn">
              <PrevBtn to="/step2" />
              <LoadingButton
                endIcon={<SendIcon />}
                loading={loadingButton}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                style={btnSubmitCustom}
                onClick={() => {
                  handleSubmit(onSubmit);
                }}
                type="submit"
              >
                <span>Bước tiếp theo</span>
              </LoadingButton>
            </div>
          </form>
        </div>
        <Footer desc={false} />
      </div>
    </>
  );
};

export default Step3;
