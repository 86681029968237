import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../assets/images/logo.png";
import Menu from "../../assets/images/menu.png";

import "./Navbar.scss";
import { RatesFee } from "../popups/RatesFee/RatesFee";
import { FAQs } from "../popups/FAQs/FAQs";
import { HowItWorksPopup } from "../popups/HowItWorksPopup/HowItWorksPopup";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openRateFee, setOpenRateFee] = useState(false);
  const [openFAQs, setOpenFAQs] = useState(false);
  const [openHowItWorks, setOpenHowItWorks] = useState(false);
  // GET VARIABLE FROM URL
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const testMode = params.get("tm");
  const testSold = params.get("ts");
  const clickId = params.get("clickId");

  testMode && localStorage.setItem("testMode", testMode);
  testSold && localStorage.setItem("testSold", testSold);
  clickId && localStorage.setItem("clickId", clickId);

  const location = useLocation();
  const subURL = location.pathname.split("/")[1];

  return (
    <nav
      className={`navbar ${
        subURL === "congratulations" && "congratulations-nav"
      }`}
      id="navbar"
    >
      {openRateFee && (
        <RatesFee openRateFee={openRateFee} setOpenRateFee={setOpenRateFee} />
      )}
      {openFAQs && <FAQs openFAQs={openFAQs} setOpenFAQs={setOpenFAQs} />}

      {openHowItWorks && (
        <HowItWorksPopup
          openHowItWorks={openHowItWorks}
          setOpenHowItWorks={setOpenHowItWorks}
        />
      )}

      <div className="container">
        <div
          className="logo"
          // data-aos="fade-right"
        >
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <button
          id="show-menu"
          onClick={() => setOpenMenu(!openMenu)}
          style={
            openMenu
              ? { transform: "rotate(90deg)" }
              : { transform: "rotate(0)" }
          }
        >
          <img src={Menu} alt="" />
        </button>
        <ul
          className="menu"
          style={openMenu ? { top: "66px" } : { top: "-100%" }}
        >
          {/* <button id="hide-menu" onClick={() => setOpenMenu(!openMenu)}>
            <img src={Close} alt="" />
          </button> */}
          {/* <li>
            {subURL === "" ? (
              <Scroll
                className="menu-item"
                to="howItWork"
                spy={true}
                smooth={true}
                duration={800}
                offset={-120}
                onClick={() => setOpenMenu(!openMenu)}
              >
                How it works
              </Scroll>
            ) : (
              <Link
                onClick={() => setOpenHowItWorks(!openHowItWorks)}
                className="menu-item"
              >
                How it works
              </Link>
            )}
          </li> */}
          <li onClick={() => setOpenMenu(!openMenu)}>
            <Link
              onClick={() => {
                setOpenMenu(!openMenu);
                setOpenRateFee(!openRateFee);
              }}
              className="menu-item"
            >
              Rates & Fees
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setOpenMenu(!openMenu);
                setOpenFAQs(!openFAQs);
              }}
              className="menu-item"
            >
              FAQs
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
