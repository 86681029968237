import React, { useRef, useEffect } from "react";
import "./HowItWorksPopup.scss";
import close from "../../../assets/images/close1.png";
import HowItWork from "../../HowItWork/HowItWork";

export const HowItWorksPopup = ({ openHowItWorks, setOpenHowItWorks }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenHowItWorks(false);
      }
    };

    if (openHowItWorks) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openHowItWorks, setOpenHowItWorks]);

  return (
    <div className={`HowItWorksPopup ${openHowItWorks ? "open" : ""}`}>
      <div className="box-popup">
        <div className="container">
          <div className="content-popup" ref={popupRef}>
            <div>
              <HowItWork />
            </div>
            <button
              className="close"
              onClick={() => setOpenHowItWorks(!openHowItWorks)}
            >
              <img src={close} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
