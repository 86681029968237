import React from "react";
import "./WhyChooseUS.scss";

const WhyChooseUS = () => {
  return (
    <div className="why-choose-us">
      <div className="container">
        <h2 className="title">Tại sao là chúng tôi?</h2>

        <p>
          <b>Seekerloans.com</b> là một đối tác tận tâm làm việc để có được sự
          tin tưởng của bạn trong việc kết nối bạn với người cho vay đáng tin
          cậy về các giải pháp tài chính. Yêu cầu trực tuyến của chúng tôi được
          thiết kế với các tiêu chí tiêu chuẩn nhằm cung cấp cho bạn một quy
          trình dễ dàng và an toàn khi gửi thông tin của bạn. Mạng lưới những
          người cho vay mà chúng tôi đang hợp tác chào đón tất cả các loại hình
          tín dụng, điều này cho phép bạn có nhiều cơ hội hơn để vay vốn.
        </p>
      </div>
    </div>
  );
};

export default WhyChooseUS;
