import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step1.scss";
import { loanReasonOptions } from "./options";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";
import Progress from "../../../components/Progress/Progress";

import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateContact } from "../../../redux/apiCalls";
import { useDispatch } from "react-redux";

import SendIcon from "@mui/icons-material/Send";

import { FormControl, MenuItem, Select } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  MenuProps,
  btnSubmitCustom,
  labelCustom,
  menuItemCustom,
  selectCustom
} from "./style";
// CUSTOM STYLE

const Step1 = () => {
  const isMobile = window.innerWidth < 768;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loanReason, setLoanReason] = useState(
    contactRedux?.loanReason ?? loanReasonOptions[3].value
  );
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  // custom
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({});

  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    const res = await updateContact(dispatch, contactRedux?._id, data);
    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/step2");
      }, 300);
    }
  };

  useEffect(() => {
    isMobile && window.scrollTo(0, 66);
  });

  return (
    <div className="step-1">
      <Navbar />
      <div className="container">
        <Progress step={1} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Lý do vay của bạn là gì?</h2>
          <div className="form-control">
            <div className="box-field">
              <Controller
                name="loanReason"
                defaultValue={loanReason}
                control={control}
                rules={{ required: "Vui lòng chọn một option!" }}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    error={!!errors?.loanReason}
                    size={isMobile ? "small" : "medium"}
                    sx={{
                      marginBottom: isMobile ? "7px" : "15px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>Lý do vay</FormHelperText>
                    <Select
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(value);
                        setLoanReason(value);
                      }}
                      value={loanReason}
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={selectCustom}
                      onClose={() => {
                        setIsSelectOpen(false);
                      }}
                      onOpen={() => {
                        setIsSelectOpen(true);
                      }}
                      className={isSelectOpen ? "show" : ""}
                    >
                      {loanReasonOptions.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.value}
                          sx={menuItemCustom}
                          size="small"
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors?.loanReason && errors?.loanReason.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className="group-btn">
            <PrevBtn to="/" />

            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitCustom}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>Bước tiếp theo</span>
            </LoadingButton>
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step1;
