import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step8.scss";
import { options } from "./options";

import Progress from "../../../components/Progress/Progress";

import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Step8 = () => {
  const [loadingButton, setLoadingButton] = useState(false);

  const isMobile = window.innerWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [incomeSource, setIncomeSource] = useState(
    contactRedux?.incomeSource ?? options[0].value
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // STYLE
  const btnSubmitStyle = {
    marginBottom: !isMobile ? "20px" : 0,
    width: "100%",
    height: !isMobile ? "50px" : "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "5px",
    background: "#F4C811",

    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: !isMobile ? "25px" : "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase"
  };
  // custom
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const onSubmit = async () => {
    if (!incomeSource) {
      setError("incomeSource", {
        type: "error",
        message: "Vui lòng chọn nguồn thu nhập"
      });
      return;
    }

    setLoadingButton(!loadingButton);

    const res = await updateContact(dispatch, contactRedux._id, {
      incomeSource: incomeSource
    });
    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/step10");
      }, 300);
    }
  };

  useEffect(() => {
    isMobile && window.scrollTo(0, 66);
  });

  return (
    <div className="step-8">
      <Navbar />
      <div className="container">
        <Progress step={8} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Nguồn thu nhập của bạn đến từ đâu?</h2>
          <h5>
            Bạn không bắt buộc phải tiết lộ thu nhập là tiền cấp dưỡng, cấp
            dưỡng nuôi con hoặc cấp dưỡng riêng trừ khi bạn muốn sử dụng thu
            nhập đó làm cơ sở để trả khoản vay này.
          </h5>

          <div className="form-control">
            <div className="list-btn-radio">
              {options.map((option, index) => (
                <button
                  key={index}
                  className={`btn-radio ${
                    incomeSource === option.value ? "active" : ""
                  }`}
                  onClick={(e) => {
                    setIncomeSource(option.value);
                    clearErrors("unsecuredDebtAmount");
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>

            {errors?.incomeSource && (
              <span className="error">{errors?.incomeSource?.message}</span>
            )}
          </div>

          <div className="group-btn">
            <PrevBtn to="/step7" />
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitStyle}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>Bước tiếp theo</span>
            </LoadingButton>
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step8;
