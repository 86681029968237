export const currentCompanyDurationOptions = [
  {
    label: "5 năm hoặc hơn",
    value: "60"
  },
  {
    label: "3 - 4 năm",
    value: "48"
  },
  {
    label: "1 - 2 năm",
    value: "24"
  },
  {
    label: "3 - 12 tháng",
    value: "12"
  },
  {
    label: "3 tháng hoăc ít hơn",
    value: "3"
  }
];
