import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step9.scss";
import { monthlyIncomeOptions, payFrequencyOptions } from "./options";
import Progress from "../../../components/Progress/Progress";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";
import SendIcon from "@mui/icons-material/Send";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import LoadingButton from "@mui/lab/LoadingButton";

import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";

import { FormControl, MenuItem, Select } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

// DATE
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  MenuProps,
  btnSubmitCustom,
  formControlCustom,
  labelCustom,
  menuItemCustom,
  selectCustom,
  textFieldCustom,
  textInputStyles
} from "./style";

// CUSTOM STYLE
import { DatePicker } from "@mui/x-date-pickers";
import { checkLastStep } from "../../../utils/checkLastStep";

const Step9 = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [openMonthlyIncome, setOpenMonthlyIncome] = useState(false);
  const [openPayFrequency, setOpenPayFrequency] = useState(false);
  const [openDateCalendar, setOpenDateCalendar] = useState(false);
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [monthlyIncome, setMonthlyIncome] = useState(
    contactRedux?.monthlyIncome ?? monthlyIncomeOptions[2].value
  );
  const [payFrequency, setPayFrequency] = useState(
    contactRedux?.payFrequency ?? payFrequencyOptions[0].value
  );
  const [nextPayDate, setNextPayDate] = useState(
    contactRedux?.nextPayDate ?? null
  );
  const [nextPayDate2, setNextPayDate2] = useState(null);

  const tomorrow = dayjs().add(0, "day");
  const maxDate = dayjs().add(30, "day");
  const isWeekend = (date) => {
    if (nextPayDate) {
      const day = dayjs(date).day();
      return day === 0 || day === 6;
    } else {
      const day = date.day();
      return day === 0 || day === 6;
    }
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    if (dayjs(nextPayDate).isBefore(dayjs(tomorrow))) {
      setError("nextPayDate", {
        type: "error",
        message: "Vui lòng chọn một ngày sau 30 ngày kể từ bây giờ!"
      });
      return;
    }

    setLoadingButton(!loadingButton);
    // Format date
    data.nextPayDate = dayjs(nextPayDate).format("MM/DD/YYYY");
    data.nextPayDate2 = dayjs(nextPayDate2).format("MM/DD/YYYY");

    try {
      const res = await updateContact(dispath, contactRedux?._id, data);

      if (res?.status === 200) {
        setTimeout(() => {
          setLoadingButton(false);
          navigate(
            checkLastStep(contactRedux) === "step8" ||
              checkLastStep(contactRedux) === "step9"
              ? "/step10"
              : `/${checkLastStep(contactRedux)}`
          );
        }, 300);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  // HANDLE NEXT PAY DATE 2
  useEffect(() => {
    if (payFrequency && nextPayDate) {
      let nextPayDate2;

      if (payFrequency === "BIWEEKLY") {
        nextPayDate2 = dayjs(nextPayDate).add(14, "day");
      } else if (payFrequency === "TWICEMONTHLY") {
        nextPayDate2 = dayjs(nextPayDate).add(14, "day");
      } else if (payFrequency === "MONTHLY") {
        nextPayDate2 = dayjs(nextPayDate).add(1, "month");
      } else if (payFrequency === "WEEKLY") {
        nextPayDate2 = dayjs(nextPayDate).add(7, "day");
      }

      // Kiểm tra nếu nextPayDate2 rơi vào thứ 7 hoặc chủ nhật
      if (nextPayDate2.day() === 6) {
        // Thứ 7
        nextPayDate2 = nextPayDate2.add(2, "day"); // Chuyển sang thứ 2
      } else if (nextPayDate2.day() === 0) {
        // Chủ nhật
        nextPayDate2 = nextPayDate2.add(1, "day"); // Chuyển sang thứ 2
      }

      setNextPayDate2(nextPayDate2.format("MM/DD/YYYY"));
    }
  }, [payFrequency, nextPayDate]);

  return (
    <div className="step-9">
      <Navbar />
      <div className="container">
        <Progress step={9} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">
            Hãy cho người cho vay biết thêm về chi tiết thu nhập của bạn để họ
            có thể xem xét yêu cầu của bạn tốt hơn!
          </h2>
          <div className="form-control">
            <Controller
              name="monthlyIncome"
              defaultValue={monthlyIncome}
              control={control}
              rules={{ required: "Monthly Income is required!" }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    ...formControlCustom,
                    marginBottom: "7px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Thu nhập hàng tháng của ban bao nhiêu?
                  </FormHelperText>
                  <Select
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value);
                      setMonthlyIncome(value);
                    }}
                    value={monthlyIncome}
                    MenuProps={MenuProps}
                    sx={selectCustom}
                    onClose={() => {
                      setOpenMonthlyIncome(false);
                    }}
                    onOpen={() => {
                      setOpenMonthlyIncome(true);
                    }}
                    className={openMonthlyIncome ? "show" : ""}
                  >
                    {monthlyIncomeOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        sx={menuItemCustom}
                        size="small"
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.monthlyIncome && errors?.monthlyIncome.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              name="payFrequency"
              defaultValue={payFrequency}
              control={control}
              rules={{ required: "Monthly Income is required!" }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    ...formControlCustom,
                    marginBottom: "7px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Chu kỳ nhận thu nhập.
                  </FormHelperText>
                  <Select
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value);
                      setPayFrequency(value);
                    }}
                    value={payFrequency}
                    displayEmpty
                    MenuProps={MenuProps}
                    sx={selectCustom}
                    onClose={() => {
                      setOpenPayFrequency(false);
                    }}
                    onOpen={() => {
                      setOpenPayFrequency(true);
                    }}
                    className={openPayFrequency ? "show" : ""}
                  >
                    {payFrequencyOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        sx={menuItemCustom}
                        size="small"
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.payFrequency && errors?.payFrequency.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
            {/* nextPayDate */}
            <Controller
              name="nextPayDate"
              defaultValue={
                nextPayDate
                  ? dayjs(dayjs(contactRedux?.nextPayDate).format("MM/DD/YYYY"))
                  : null
              }
              control={control}
              rules={{ required: "Next Pay Date is required!" }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  sx={{
                    ...formControlCustom,
                    marginBottom: "15px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Thời gian nhận khoản thu nhập.
                  </FormHelperText>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      {...field}
                      open={openDateCalendar}
                      onClose={() => setOpenDateCalendar(!openDateCalendar)}
                      slotProps={{
                        textField: {
                          onClick: () => setOpenDateCalendar(!openDateCalendar),
                          size: windowWidth > 768 ? "medium" : "small",
                          sx: textFieldCustom,
                          error: errors?.nextPayDate,
                          InputLabelProps: {
                            shrink: false,
                            style: { ...textInputStyles, color: "#B7B7B7" }
                          },
                          helperText:
                            errors?.nextPayDate && errors?.nextPayDate?.message
                        }
                      }}
                      minDate={tomorrow}
                      maxDate={maxDate}
                      format="MM-DD-YYYY"
                      shouldDisableDate={isWeekend}
                      onChange={(date) => {
                        field.onChange(date);
                        setNextPayDate(date);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
            />

            {/* nextPayDate */}
          </div>
          <div className="group-btn">
            <PrevBtn to="/step8" />
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitCustom}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>Bước tiếp theo</span>
            </LoadingButton>
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step9;
