import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./ContactUs.scss";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className="ContactUs">
      <Navbar />

      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Liên hệ với chúng tôi</h2>

          <p>
            Liên hệ với chúng tôiNếu có điều gì đó bạn muốn thảo luận với chúng
            tôi liên quan đến trang web, vui lòng liên hệ với chúng tôi theo
            thông tin liên hệ bên dưới. Nếu bạn có bất kỳ câu hỏi nào liên quan
            đến yêu cầu vay vốn, vui lòng liên hệ trực tiếp với người cho vay.
          </p>

          <h4>Địa chỉ gửi thư: </h4>
          <li>
            <b>seekerloans.com</b>
          </li>
          <li>2803 Philadelphia Pike</li>
          <li>Suite B #1020</li>
          <li>Claymont, DE 19703</li>
          <li>Hoa Kỳ</li>
          <li>
            Email:{" "}
            <b>
              <Link target="_blank" to="mailto:contact@seekerloans.com">
                contact@seekerloans.com
              </Link>
            </b>
          </li>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default ContactUs;
