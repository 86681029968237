import React from "react";
import "./Configuration.scss";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import checkedIcon from "../../../assets/images/checked.png";

const Configuration = () => {
  const isMobile = window.innerWidth < 768;

  return (
    <Box
      className="configuration-page"
      style={{
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh"
      }}
    >
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </nav>

      <div className="step-main">
        <div className="container">
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <img src={checkedIcon} alt="" />
          </div>
          <h1 className="h1-title">
            Cảm ơn bạn đã dành thời gian! Kiểm tra hộp thư đến của bạn để biết
            thông tin cập nhật.
          </h1>
          <Box
            sx={{
              width: "100%",
              maxWidth: "900px",
              padding: isMobile ? "25px 15px 30px" : "41px 30px 52px",
              boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              border: "1px solid #33A8FC",
              backgroundColor: "#fff"
            }}
          >
            <p className="desc-configuration">
              Chúng tôi hiện đang tìm kiếm cơ sở dữ liệu rộng lớn của mình để
              đảm bảo bạn được kết nối với người cho vay đáp ứng nhu cầu của
              bạn. Bạn sẽ được chuyển hướng đến người cho vay của bạn sau khi
              hoàn thành.<br></br> Chúng tôi không phải là người cho vay. Chúng
              tôi sẽ sớm kết nối bạn với người cho vay. Nếu bạn muốn hủy đơn
              đăng ký của mình, bạn cần đợi cho đến khi được chuyển hướng đến
              trang web của người cho vay. Bạn không bao giờ có nghĩa vụ phải
              chấp nhận bất kỳ lời đề nghị nào! Sau khi được chuyển hướng, bạn
              có thể chấp nhận khoản vay bằng cách ký chữ ký điện tử hoặc hủy
              đơn đăng ký.
            </p>
          </Box>
        </div>
      </div>

      <footer>
        <p>© 2023 Seekerloans.com All rights reserved.</p>
      </footer>
    </Box>
  );
};

export default Configuration;
