import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { checkLastStep } from "../../utils/checkLastStep";

import DataProtected from "../../assets/images/security/DataProtected.png";
import Clear from "../../assets/images/security/Clear.png";

import "./Banner.scss";

import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import { addContact } from "../../redux/apiCalls";
import { useNavigate } from "react-router-dom";

import { RatesFee } from "../popups/RatesFee/RatesFee";

import SendIcon from "@mui/icons-material/Send";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField
} from "@mui/material";
import {
  ListBoxCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles,
  btnSubmitStyle,
  useStyles
} from "./style";

import {
  autoCompleteEmailDomain,
  getYourIpAddress,
} from "../../utils/functions";
const emailDomains = [
  "gmail.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "yahoo.com",
  "icloud.com",
  "yandex.com"
];
const Banner = () => {
  // STYLE
  const classes = useStyles();
  const isMobile =  window.innerWidth < 768;

  const [openRateFee, setOpenRateFee] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [amount, setAmount] = useState(contactRedux?.amount ?? "");
  const [email, setEmail] = useState(contactRedux?.email ?? "");
  const [emailOptions, setEmailOptions] = useState([]);
  const [openEmailOptions, setOpenEmailOptions] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e, newInputValue) => {
    if (newInputValue.includes("@"))
      setEmailOptions(autoCompleteEmailDomain(newInputValue, emailDomains));
    else setEmailOptions([]);
  };
  // SUBMIT FUNC
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });
  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    data.ipAddress = await getYourIpAddress();
    data.userAgent = navigator.userAgent;

    const res = await addContact(dispatch, data);

    if (res?.status === 200) {
      setLoadingButton(false);
      // CHECK STEP
      // const lastStep = checkLastStep(res?.contact);
      // const stepsRequiringPayFrequency = [
      //   "step10",
      //   "step11",
      // ];
      // if (stepsRequiringPayFrequency.includes(lastStep)) navigate("/step9");
      navigate("/step1");
    } else {
      setError("email", {
        type: "error",
        message: res?.message
      });
      setLoadingButton(false);
    }
    return;
  };

  return (
    <div className="banner" id="banner">
      {openRateFee && (
        <RatesFee openRateFee={openRateFee} setOpenRateFee={setOpenRateFee} />
      )}
      <div className="container">
        {isMobile && (
          <h2 className="title">
            Nhận ngay 500.000 VNĐ khi gửi yêu cầu đăng ký vay tiền dưới đây!
          </h2>
        )}
        <div className="form" data-aos="zoom-in-down">
          {!isMobile && (
            <h2 className="title">
              Nhận ngay 500.000 VNĐ khi gửi yêu cầu đăng ký vay tiền dưới đây!
            </h2>
          )}
          {/* FORM */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="amount"
              control={control}
              defaultValue={amount}
              rules={{
                required: "Bắt buộc điền số tiền vay",
                // validate: (value) => {
                //   return validateAmout(value);
                // }
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl fullWidth>
                  <FormHelperText sx={labelCustom}>Số tiền vay</FormHelperText>
                  <NumericFormat
                    {...field}
                    inputRef={(input) => {
                      input?.addEventListener("focus", () => {
                        input?.setAttribute("inputmode", "numeric");
                      });
                      ref(input);
                    }}
                    // prefix="$"
                    suffix=" VNĐ"
                    id="amount"
                    autoComplete="amount"
                    className={classes.textField}
                    size={!isMobile ? "medium" : "small"}
                    sx={textFieldCustom}
                    label={amount ? "" : "VD: 500.000 VNĐ"}
                    fullWidth
                    thousandSeparator
                    customInput={TextField}
                    error={!!errors?.amount}
                    helperText={errors?.amount && errors?.amount?.message}
                    value={amount}
                    onValueChange={(values) => {
                      const { value } = values;
                      setAmount(value);
                    }}
                    InputLabelProps={{
                      shrink: false
                    }}
                    // isAllowed={withValueLimit}
                  />
                </FormControl>
              )}
            />

            <Controller
              name="email"
              control={control}
              defaultValue={email}
              rules={{
                required: "Vui lòng nhập email!",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Địa chỉ email không hợp lệ!"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    marginBottom: isMobile ? "7px" : "15px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Địa chỉ email của bạn
                  </FormHelperText>
                  <Autocomplete
                    {...field}
                    freeSolo
                    id="email"
                    name="email"
                    onOpen={() => setOpenEmailOptions(true)}
                    onClose={() => setOpenEmailOptions(false)}
                    disableClearable
                    value={email}
                    options={emailOptions}
                    onChange={(e, option) => {
                      setEmail(option);
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleInputChange(event, newInputValue);
                      field.onChange(newInputValue);
                    }}
                    ListboxComponent="div"
                    ListboxProps={{ sx: ListBoxCustom }}
                    //PopperComponent={isMobile && "bottom"}
                    noOptionsText={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={email ? "" : ""}
                        size={isMobile ? "small" : "medium"}
                        sx={{
                          ...textFieldCustom
                        }}
                        className={
                          openEmailOptions && emailOptions.length > 0
                            ? "show"
                            : ""
                        }
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            ...textInputStyles,
                            color: "#B7B7B7"
                          }
                        }}
                        value={email}
                        error={!!errors?.email}
                        helperText={errors?.email && errors?.email?.message}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            <p className="tcpa">
              Bằng cách nhấp vào <b>'Bắt đầu'</b>, bạn đồng ý với{" "}
              <Link to="/privacy">Chính sách quyền riêng tư</Link>,{" "}
              <Link to="/terms-of-use">Điều khoản</Link>,{" "}
              <Link onClick={() => setOpenRateFee(!openRateFee)}>
                Giá & Phí
              </Link>{" "}
              của chúng tôi và nhận các ưu đãi đặc biệt từ chúng tôi và các đối
              tác tiếp thị của chúng tôi qua liên lạc qua email
            </p>

            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitStyle}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>Bắt đầu</span>
            </LoadingButton>
          </form>

          {/* FORM */}

          {!isMobile && (
            <div className="list-security">
              <div className="item">
                <img src={Clear} alt="DataProtected" />
                <p>Dữ liệu được bảo vệ</p>
              </div>

              <div className="item">
                <img src={DataProtected} alt="DataProtected" />
                <p>Dữ liệu được mã hoá</p>
              </div>
            </div>
          )}
        </div>
        {isMobile && (
          <div className="list-security">
            <div className="item">
              <img src={Clear} alt="DataProtected" />
              <p>Dữ liệu được bảo vệ</p>
            </div>
            <div className="item">
              <img src={DataProtected} alt="DataProtected" />
              <p>Dữ liệu được mã hoá</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
