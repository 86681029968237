import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Privacy.scss";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div className="Privacy">
      <Navbar />

      <div className="container-privacy">
        <div className="box-privacy">
          <h2 className="title">Chính sách bảo mật</h2>
          <p className="last-update">Cập nhật lần cuối: 22-09-2023</p>
         
          <h4>Không gọi Tuân thủ đăng ký</h4>
          <p>
            Mọi người tiêu dùng đều có quyền thêm số điện thoại của mình vào Cơ
            quan đăng ký quốc gia không gọi điện, nói chung, cơ quan này áp dụng
            cho mọi kế hoạch, chương trình hoặc chiến dịch bán hàng hóa hoặc
            dịch vụ thông qua các cuộc gọi điện thoại giữa các tiểu bang. Tuy
            nhiên, một công ty có thể gọi điện cho người tiêu dùng mà công ty đã
            thiết lập mối quan hệ kinh doanh trong tối đa mười tám (18) tháng kể
            từ lần mua hàng, giao hàng hoặc thanh toán cuối cùng của người tiêu
            dùng và trong tối đa ba (3) tháng sau khi người tiêu dùng nộp đơn
            đăng ký hoặc một cuộc điều tra tới công ty, ngay cả khi số điện
            thoại của người tiêu dùng đã được thêm vào Danh sách đăng ký không
            gọi quốc gia. Seekerloans.com không thực hiện các cuộc gọi điện
            thoại đi tới người tiêu dùng và do đó không duy trì sổ đăng ký không
            gọi nội bộ của riêng mình. Nếu bạn không muốn nhận cuộc gọi từ người
            cho vay mà bạn đã kết nối nữa, bạn sẽ phải liên hệ trực tiếp với họ
            và yêu cầu đưa số điện thoại của bạn vào danh sách không gọi cụ thể
            của họ.
          </p>
          <h4>Lưu giữ và bảo mật dữ liệu</h4>
          <p>
            Chúng tôi sẽ lưu giữ thông tin của bạn trong khoảng thời gian cần
            thiết để cung cấp cho bạn các sản phẩm và Dịch vụ cũng như để tuân
            thủ các nghĩa vụ pháp lý và kế toán của chúng tôi, giải quyết tranh
            chấp và thực thi các thỏa thuận của chúng tôi. Thông tin cá nhân của
            bạn sẽ bị hủy hoặc xóa vĩnh viễn theo yêu cầu của pháp luật hiện
            hành. Seekerloans.com sử dụng các biện pháp bảo mật kỹ thuật, vật lý
            và hành chính hợp lý để giảm nguy cơ mất mát, lạm dụng, truy cập
            trái phép, tiết lộ hoặc sửa đổi thông tin của bạn. Thật không may,
            không có việc truyền dữ liệu qua Internet nào được đảm bảo an toàn
            tuyệt đối. Mặc dù chúng tôi cố gắng bảo vệ Thông tin cá nhân của bạn
            nhưng chúng tôi không thể đảm bảo hoặc đảm bảo tính bảo mật hoặc bí
            mật của bất kỳ Thông tin cá nhân nào bạn truyền cho chúng tôi.
          </p>
          <h4>Liên kết đến trang web của bên thứ ba</h4>
          <p>
            Trang web của chúng tôi, cũng như các email được gửi liên quan đến
            Dịch vụ của chúng tôi, có thể chứa các liên kết hoặc quyền truy cập
            vào các trang web do bên thứ ba điều hành nằm ngoài tầm kiểm soát
            của chúng tôi. Ví dụ: nếu bạn nhấp để yêu cầu một sản phẩm như khoản
            vay qua Seekerloans.com, bạn có thể được liên kết với trang web của
            bên thứ ba để hoàn thành yêu cầu. Các liên kết hoặc quyền truy cập
            vào bên thứ ba từ Dịch vụ của chúng tôi không phải là sự chứng thực
            của chúng tôi đối với bên thứ ba đó hoặc trang web, yêu cầu, sản
            phẩm, dịch vụ hoặc hoạt động của họ. Chúng tôi không chịu trách
            nhiệm về chính sách bảo mật, điều khoản và điều kiện, thông lệ hoặc
            nội dung của các bên thứ ba đó.
          </p>
          <h4>Đồng ý điện tử</h4>
          <p>
            Vui lòng đọc kỹ và ghi nhận thông tin quan trọng này để có thể giúp
            bạn tham khảo trong tương lai. Bằng cách tham gia vào trang web này
            và nhấp vào nút 'Yêu cầu ngay', bạn đồng ý cung cấp thông tin cá
            nhân của mình để yêu cầu liên hệ với người cho vay của chúng tôi và
            bạn đã nhận được cũng như xem xét Chính sách đồng ý điện tử này và
            bạn đồng ý thực hiện các giao dịch bằng cách sử dụng chữ ký điện tử,
            tiết lộ điện tử, hồ sơ điện tử và tài liệu hợp đồng điện tử ("Tiết
            lộ"). Trong nỗ lực kết nối bạn với người cho vay, người cho vay cần
            có sự đồng ý của bạn để sử dụng và chấp nhận chữ ký, hồ sơ và tiết
            lộ điện tử ("Đồng ý điện tử"). Tùy chọn cho hồ sơ giấy hoặc phi điện
            tử. Về hồ sơ giấy, bạn có thể kết nối trực tiếp với người cho vay để
            yêu cầu Tiết lộ mà không mất bất kỳ khoản phí nào. Phạm vi của sự
            đồng ý. Thông tin bạn cung cấp sẽ được chia sẻ với những người cho
            vay tham gia, đối tác bên thứ ba bao gồm nhà quảng cáo, mạng và
            những người khác không thể cung cấp giải pháp tài chính, kết nối
            giữa bạn và đối tác chia sẻ thông tin có thể trên bất kỳ thiết bị di
            động nào, bao gồm điện thoại, điện thoại thông minh , máy tính bảng
            và bất kỳ thiết bị điện tử nào khác cho phép người tiêu dùng kết nối
            với Internet. Chủ sở hữu trang web này không có sự đảm bảo nào về
            các khoản vay được phê duyệt. Bằng cách thực hiện Đồng ý điện tử
            này, các bên thứ 3 này có thể xử lý thông tin của bạn và tương tác
            điện tử trong tất cả các tương tác trực tuyến với bạn. Họ cũng có
            thể gửi cho bạn các thông báo điện tử liên quan đến các tương tác và
            giao dịch của họ. Thông tin tiết lộ có thể được cung cấp trực tuyến
            tại trang web của người cho vay, nếu có và có thể được cung cấp qua
            e-mail. Xem ở trên để được hướng dẫn về cách yêu cầu bản sao giấy.
            Đồng ý thực hiện kinh doanh điện tử.
          </p>
          <p>
            Trước khi đưa ra quyết định kinh doanh cuối cùng với người cho vay,
            điều quan trọng là bạn phải cân nhắc hai điểm dưới đây: Tiến hành
            kinh doanh điện tử Yêu cầu khả năng phần cứng và phần mềm được mô tả
            như dưới đây.
          </p>
          <p>
            Yêu cầu phần cứng và phần mềm. Để truy cập và lưu giữ các Tiết lộ và
            Thông tin liên lạc dưới dạng điện tử, bạn sẽ cần sử dụng phần mềm và
            phần cứng máy tính sau: Truy cập Internet với mã hóa 128-bit Adobe
            Acrobat Reader 6 trở lên Khả năng in Internet Explorer 8 trở lên
            Truy cập email Firefox phiên bản 3.6 trở lên Khả năng của bạn để
            truy cập tiết lộ.
          </p>
          <p>
            {" "}
            Bằng cách nhấp vào và gửi thông tin của bạn, bạn đồng ý với các điều
            khoản và điều kiện của chúng tôi. Bạn xác nhận rằng bạn có thể truy
            cập các thông tin tiết lộ theo các định dạng được chỉ định ở trên.
            Rút lại sự đồng ý. Gửi ý kiến phản hồi Bảng điều khiển bên Các bản
            dịch đã thực hiện Đã lưu
          </p>
          <h4>Thỏa thuận và thừa nhận</h4>
          <p>
            Dịch vụ này được điều hành bởi Seekerloans.com (sau đây gọi là "Công
            ty"). Chính sách quyền riêng tư này là Thỏa thuận giữa Công ty và
            Bạn (sau đây gọi là "Bạn" và/hoặc "Của bạn") với tư cách là người
            dùng Dịch vụ này cũng như các sản phẩm và dịch vụ của Công ty và
            điều chỉnh cách Công ty sử dụng thông tin được thu thập cũng như bảo
            vệ thông tin cá nhân. thông tin do Bạn gửi cho Dịch vụ này. Bằng
            việc truy cập trang web của chúng tôi, bạn xác nhận rằng trang web
            này dành cho những cá nhân trên 18 tuổi hợp pháp và những cá nhân là
            công dân hợp pháp của Hoa Kỳ, bạn biết về các hoạt động tiếp thị của
            chúng tôi và đồng ý với họ. Bạn không có quyền phản đối việc nhận
            quảng cáo, bản tin, thông điệp tiếp thị và các tài liệu quảng cáo
            khác từ chúng tôi. Mọi câu hỏi liên quan đến Chính sách quyền riêng
            tư hoặc phương pháp thu thập thông tin của chúng tôi phải được gửi
            cho chúng tôi qua email tại contact@seekerloans.com
          </p>
          <h4>Thông báo về thay đổi chính sách quyền riêng tư</h4>
          <p>
            Chúng tôi có quyền sửa đổi, cập nhật Chính sách quyền riêng tư này
            mà không cần thông báo thêm cho người dùng. Bạn nên tiếp tục xem lại
            Chính sách này định kỳ để đảm bảo rằng bạn được cập nhật và hiểu
            biết về mọi thay đổi đang diễn ra.
          </p>
          <h4>Những thông tin chúng tôi thu thập</h4>
          <p>
            Chúng tôi thu thập thông tin sau từ bạn, thông tin nhận dạng cá nhân
            và thông tin không thể nhận dạng cá nhân.
          </p>
          <p>
            Thông tin do bạn cố ý cung cấp thông qua các biểu mẫu trực tuyến,
            biểu mẫu đăng ký, khảo sát và/hoặc các mục nhập khác, chẳng hạn như
            tên, địa chỉ đường phố, địa chỉ email, số điện thoại (nhà, nơi làm
            việc và điện thoại di động), ngày sinh, Số An sinh Xã hội, thông tin
            tài khoản ngân hàng, thông tin liên quan đến thu nhập hàng tháng,
            thông tin việc làm và thông tin cá nhân, tài chính hoặc nhân khẩu
            học khác;
          </p>
          <p>
            Bánh quy. Khi bạn điều hướng và tương tác với Trang web này, chúng
            tôi có thể sử dụng các công nghệ thu thập dữ liệu tự động ("Cookie")
            để thu thập một số thông tin nhất định, bao gồm nhưng không giới hạn
            ở thông tin về thiết bị của bạn, hành động và kiểu duyệt web, hệ
            điều hành, kết nối internet và vị trí dữ liệu. Thông tin chúng tôi
            thu thập tự động có thể bao gồm thông tin cá nhân và chúng tôi có
            thể duy trì hoặc kết hợp thông tin đó với thông tin cá nhân mà chúng
            tôi thu thập theo cách khác hoặc nhận được từ bên thứ ba. Cookie
            giúp chúng tôi cải thiện Trang web này để chúng tôi có thể mang lại
            trải nghiệm tốt hơn và được cá nhân hóa hơn bằng cách giảm thông tin
            không mong muốn mà bạn có thể nhận được bằng cách khác Thông tin
            (dành riêng cho người dùng hoặc tổng hợp) liên quan đến các trang mà
            khách truy cập của chúng tôi truy cập và các URL giới thiệu; Và Địa
            chỉ email của khách truy cập liên lạc với trang web này và Steady
            Cash qua email. Xin lưu ý: Việc gửi thông tin cho chúng tôi là tự
            nguyện và chúng tôi cũng có thể thu thập thông tin nhận dạng cá nhân
            của bạn khi bạn gửi thông tin tại Trang web nhưng không hoàn tất quy
            trình đăng ký đầy đủ.
          </p>
          <h4>Cách chúng tôi sử dụng ngày thu thập</h4>
          <p>
            Vì mục đích sử dụng dữ liệu, chúng tôi sử dụng thông tin chúng tôi
            thu thập để nâng cao trải nghiệm của bạn tại Trang web của chúng tôi
            và giúp chúng tôi cung cấp nội dung mà chúng tôi xác định có thể
            khiến bạn quan tâm. Chúng tôi sử dụng thông tin liên hệ của bạn để
            cung cấp cho bạn tài liệu quảng cáo từ các đối tác của chúng tôi.
            Chúng tôi cũng có thể sử dụng dữ liệu cá nhân, nhân khẩu học và hồ
            sơ của bạn để cải thiện Trang web của chúng tôi, tiến hành phân tích
            thống kê và cho các mục đích tiếp thị, quảng cáo, biên tập hoặc phản
            hồi. Thông tin do chúng tôi thu thập có thể được thêm vào cơ sở dữ
            liệu của chúng tôi và được sử dụng cho các email hoặc gửi thư về sản
            phẩm và dịch vụ trong tương lai.
          </p>
          <h4>Cách chúng tôi chia sẻ thông tin của bạn</h4>
          <p>
            Bằng cách cung cấp thông tin nhận dạng cá nhân của bạn, bạn cho phép
            chúng tôi xử lý yêu cầu của bạn và kết nối bạn với người cho vay.
            Điều đó cũng có nghĩa là chúng tôi có quyền chia sẻ thông tin này
            với bên thứ ba vì người cho vay không thể phê duyệt khoản vay của
            bạn nếu không biết gì về bạn. Xin lưu ý rằng người cho vay có thể
            xác minh thông tin cá nhân của bạn bằng cách gửi yêu cầu đến cơ sở
            dữ liệu quốc gia và cơ quan báo cáo tín dụng. Những câu hỏi như vậy
            có thể ảnh hưởng đến điểm tín dụng của bạn. Chúng tôi có thể chia sẻ
            một số dữ liệu của bạn với các chi nhánh, đối tác và các bên thứ ba
            được công nhận khác nhằm mục đích quản lý kinh doanh, bảo mật, quảng
            cáo, tiếp thị, nghiên cứu hành vi người tiêu dùng và cải thiện hỗ
            trợ người tiêu dùng.
          </p>
          <p>
            Chúng tôi có quyền chia sẻ thông tin của bạn với một công ty khác đã
            mua lại hoạt động kinh doanh của chúng tôi, một phần của nó hoặc hợp
            nhất với chúng tôi. Trong trường hợp tình huống như vậy xảy ra,
            chúng tôi có thể sẽ thông báo cho bạn về những thay đổi này qua
            email hoặc bằng thông báo đăng trên trang web của chúng tôi. Chúng
            tôi có thể chia sẻ thông tin nhận dạng cá nhân của bạn nếu luật pháp
            yêu cầu và cần thiết để tiến hành thủ tục pháp lý hoặc để bảo vệ
            quyền của chúng tôi. Bằng cách thu thập và chia sẻ thông tin không
            thể nhận dạng cá nhân của bạn với bên thứ ba, chúng tôi cải thiện
            dịch vụ kỹ thuật của mình, nghiên cứu hành vi của khách hàng và thu
            thập số liệu thống kê web quan trọng đối với việc quản lý doanh
            nghiệp của chúng tôi. Chúng tôi có thể chia sẻ nó với các bên thứ ba
            khác cho những mục đích này.
          </p>
          <p>
            Nếu bạn được kết nối với một hoặc nhiều người cho vay bên thứ ba,
            bạn có thể rút lại Đồng ý điện tử của mình với những người cho vay
            đó bất kỳ lúc nào và không mất phí. Tuy nhiên, nếu bạn rút lại Đồng
            ý điện tử này trước khi kết nối với người cho vay, bạn có thể không
            nhận được lời đề nghị vì sự đồng ý này là bắt buộc đối với giao dịch
            một lần này, để tạo thuận lợi cho việc kết nối và tiến hành kinh
            doanh. Hãy liên hệ trực tiếp với người cho vay của bạn nếu bạn muốn
            rút lại Đồng ý điện tử này. Nếu bạn quyết định rút lại Đồng ý điện
            tử này, hiệu lực pháp lý, hiệu lực và khả năng thực thi của việc
            tiết lộ điện tử trước đó sẽ không bị ảnh hưởng.
          </p>
          <p>
            Thay đổi thông tin liên hệ của bạn. Bạn nên thông báo cho người cho
            vay về bất kỳ thay đổi nào về địa chỉ điện tử hoặc địa chỉ gửi thư
            thực tế của bạn. Người cho vay của bạn sẽ có hướng dẫn cụ thể về
            cách cập nhật thông tin liên hệ của bạn, nếu cần. Xin lưu ý rằng
            seekerloans.com không thể cập nhật thông tin cho bạn.
          </p>
          <h4>Quyền của California - Shine the Light</h4>
          <p>
            Theo Mục 1798.83 của Bộ luật Dân sự California, cư dân California
            cung cấp thông tin cá nhân để nhận sản phẩm hoặc dịch vụ cho bất kỳ
            mục đích nào đều có quyền yêu cầu và lấy từ chúng tôi về thông tin
            khách hàng mà chúng tôi đã chia sẻ với tất cả các đối tác, kể cả bên
            thứ ba. Thông tin này bao gồm tên, địa chỉ của các doanh nghiệp đó.
            Tất cả các đối tác của chúng tôi sẽ được liệt kê rõ ràng trong phần
            Đối tác tiếp thị của chúng tôi, nhưng nếu bạn có bất kỳ yêu cầu nào
            để lấy thông tin này, vui lòng gửi email đến contact@seekerloans.com
            với dòng chủ đề: “Yêu cầu quyền của California để lấy thông tin' .
            Xin lưu ý rằng chỉ những thông tin liên quan đến chủ đề "Tỏa sáng"
            mới được chia sẻ trong phản hồi của chúng tôi.{" "}
          </p>
          <h4>Quyền từ chối</h4>
          <p>
            Chúng tôi có thể thu thập thông tin về bạn, đặc biệt là địa chỉ
            email của bạn từ các nguồn khác ngoài bạn, chẳng hạn như từ các
            trang web truyền thông xã hội (ví dụ: Facebook, LinkedIn, Twitter
            hoặc các nguồn khác), blog, người dùng khác và đối tác kinh doanh
            của chúng tôi. Nếu bạn không muốn nhận các ưu đãi hoặc bản tin qua
            email từ chúng tôi, bạn có thể từ chối nhận thông tin email từ chúng
            tôi (ngoài các email liên quan đến việc hoàn tất đăng ký, chỉnh sửa
            dữ liệu người dùng, thay đổi mật khẩu và các thông tin liên lạc
            tương tự khác cần thiết cho giao dịch của bạn thông qua Dịch vụ của
            chúng tôi) bằng cách sử dụng quy trình hủy đăng ký ở cuối email.
          </p>
          <h4>Thông báo đẩy</h4>
          <p>
            Chúng tôi có thể gửi thông báo đẩy hoặc cảnh báo tới thiết bị của
            bạn. Chúng tôi sử dụng thông tin đẩy để gửi cho bạn thông báo liên
            quan đến ưu đãi. Bất cứ lúc nào, bạn có thể quản lý tùy chọn thông
            báo đẩy của mình hoặc tắt các thông báo này bất kỳ lúc nào bằng cách
            tắt thông báo trong cài đặt trình duyệt của thiết bị.
          </p>
          <h4>Liên hệ chúng tôi</h4>
          <p>
            Nếu có điều gì bạn muốn thảo luận với chúng tôi liên quan đến trang
            web, vui lòng liên hệ với chúng tôi theo thông tin liên hệ bên dưới.
            Nếu bạn có bất kỳ câu hỏi nào liên quan đến yêu cầu vay vốn, vui
            lòng liên hệ trực tiếp với người cho vay.
          </p>
          <h4>Địa chỉ gửi thư: </h4>
          <li>
            <b>seekerloans.com</b>
          </li>
          <li>2803 Philadelphia Pike</li>
          <li>Suite B #1020</li>
          <li>Claymont, DE 19703</li>
          <li>Hoa Kỳ</li>
          <li>
            Email:{" "}
            <b>
              <Link target="_blank" to="mailto:contact@seekerloans.com">
                contact@seekerloans.com
              </Link>
            </b>
          </li>
        </div>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Privacy;
