import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import "./theme.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Step1 from "./pages/Steps/Step1/Step1";
import Step2 from "./pages/Steps/Step2/Step2";
import Step3 from "./pages/Steps/Step3/Step3";
import Step4 from "./pages/Steps/Step4/Step4";
import Step5 from "./pages/Steps/Step5/Step5";
import Step6 from "./pages/Steps/Step6/Step6";
import Step7 from "./pages/Steps/Step7/Step7";
import Step8 from "./pages/Steps/Step8/Step8";
import Step9 from "./pages/Steps/Step9/Step9";
import Step10 from "./pages/Steps/Step10/Step10";
import Step11 from "./pages/Steps/Step11/Step11";


import Unsubscribe from "./pages/AnotherPages/Unsubscribe/Unsubscribe";
import DoNotSellInformation from "./pages/AnotherPages/DoNotSellInformation/DoNotSellInformation";
import TermsOfUse from "./pages/AnotherPages/TermsOfUse/TermsOfUse";
import Privacy from "./pages/AnotherPages/Privacy/Privacy";
import ContactUs from "./pages/AnotherPages/ContactUs/ContactUs";
import Disclaimer from "./pages/AnotherPages/Disclaimer/Disclaimer";
import FrequentlyAskedQuestions from "./pages/AnotherPages/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
// import Configuration from "./pages/Steps/Configuration/Configuration";
import RedirectPage from "./pages/Steps/RedirectPage/RedirectPage";
import Configuration from "./pages/Steps/Configuration/Configuration";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000
    });
    AOS.refresh();
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/step1" element={<Step1 />}></Route>
        <Route path="/step2" element={<Step2 />}></Route>
        <Route path="/step3" element={<Step3 />}></Route>
        <Route path="/step4" element={<Step4 />}></Route>
        <Route path="/step5" element={<Step5 />}></Route>
        <Route path="/step6" element={<Step6 />}></Route>
        <Route path="/step7" element={<Step7 />}></Route>
        <Route path="/step8" element={<Step8 />}></Route>
        <Route path="/step9" element={<Step9 />}></Route>
        <Route path="/step10" element={<Step10 />}></Route>
        <Route path="/step11" element={<Step11 />}></Route>
        <Route path="/unsubscribe" element={<Unsubscribe />}></Route>
        <Route
          path="/do-not-sell-information"
          element={<DoNotSellInformation />}
        ></Route>
        <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/disclaimer" element={<Disclaimer />}></Route>
        <Route
          path="/frequently-asked-questions"
          element={<FrequentlyAskedQuestions />}
        ></Route>
        {/* <Route path="/configuration" element={<Configuration />}></Route> */}
        <Route path="/congratulations" element={<Configuration />}></Route>
       
        <Route path="/redirect" element={<RedirectPage />}></Route>
      </Routes>
    </Router>
  );
};
export default App;
