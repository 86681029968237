import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step2.scss";
import Progress from "../../../components/Progress/Progress";

import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

import { Controller, useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import dayjs from "dayjs";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField
} from "@mui/material";

import { searchFirstNameApi, searchLastNameApi } from "../../../utils/callApi";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import { monthOptions } from "./options";
import {
  ListBoxCustom,
  MenuProps,
  btnSubmitCustom,
  formControlCustom,
  labelCustom,
  menuItemCustom,
  selectCustom,
  textFieldCustom,
  textInputStyles
} from "./style";

import { checkAge } from "../../../utils/validationUtils";
import {
  handleAutocompleteField,
  trimWhiteSpaceObjValue
} from "../../../utils/functions";

const Step2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [firstName, setFirstName] = useState(contactRedux?.firstName ?? "");
  const [firstNameOptions, setFirstNameOptions] = useState([]);
  const [openFirstName, setOpenFirstName] = useState(false);

  const [lastName, setLastName] = useState(contactRedux?.lastName ?? "");
  const [lastNameOptions, setLastNameOptions] = useState([]);
  const [openLastName, setOpenLastName] = useState(false);

  const [openMonthOptions, setOpenMonthOptions] = useState(false);
  const [openDayOptions, setOpenDayOptions] = useState(false);
  const [openYearOptions, setOpenYearOptions] = useState(false);

  const [dob, setDob] = useState({
    month: contactRedux?.dob?.split("-")[0] || "",
    day: contactRedux?.dob?.split("-")[1] || "",
    year: contactRedux?.dob?.split("-")[2] || "",
    totalDayInMonth: 0
  });

  const timeoutRef = useRef(null);
  // CALL API SEARCH FIRSTNAME
  const handleSearchFirstName = async (firstName) => {
    const options = await handleAutocompleteField(firstName, timeoutRef, searchFirstNameApi);
    options.length > 0 && setFirstNameOptions(options);
  };
  // CALL API SEARCH LASTNAME
  const handleSearchLastName = async (lastName) => {
    const options = await handleAutocompleteField(lastName, timeoutRef, searchLastNameApi);
    options.length > 0 && setLastNameOptions(options);
  };

  useEffect(() => {
    // CHECK TOTAL DAY IN MONTH
    if (dob.month) {
      const isValidMonth = dayjs(dob.month, "MM").isValid();
      if (isValidMonth) {
        const totalDayInMonth = dayjs(dob.month, "MM").daysInMonth();
        setDob((prev) => ({ ...prev, totalDayInMonth }));
      }
    }

    // CHECK AGE
    if (dob.year) {
      const isValidYear = dayjs(`${dob.year}`, "YYYY").isValid();
      if (isValidYear) {
        const age = checkAge(dob.year);
        age < 18
          ? setError("age", {
              type: "manual",
              message: "You are not yet 18 years old"
            })
          : clearErrors("age");
      }
    }
  }, [dob.month, dob.year]);

  useEffect(() => {
    isMobile && window.scrollTo(0, 66);
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  // HANDLE SUBMIT FORM
  const handleFormSubmit = async (data) => {
    setLoadingButton(true);

    try {
      const convertedDate = `${data.month}-${data.day}-${data.year}`;
      data.dob = convertedDate;

      // DELETE WHITE SPACE FROM OBJECT VALUE
      trimWhiteSpaceObjValue(data);

      const res = await updateContact(dispatch, contactRedux?._id, data);

      const email = contactRedux?.email;
      if (email && res?.status === 200) {
        setTimeout(() => {
          setLoadingButton(false);
          navigate("/step3");
        }, 300);
      }
    } catch (error) {
      console.error("Error updating contact:", error);
      setLoadingButton(false);
    }
  };
  const onSubmit = handleSubmit(handleFormSubmit);

  return (
    <div className="step-2">
      <Navbar />
      <div className="container">
        <Progress step={2} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Nào! Hãy cho chúng tôi biết về bạn?</h2>
          <div className="form-control">
            {/* firstName */}
            <Controller
              name="firstName"
              control={control}
              defaultValue={firstName}
              rules={{
                required: "Vui lòng điền tên!"
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    ...formControlCustom,
                    marginBottom: "7px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>Tên</FormHelperText>
                  <Autocomplete
                    {...field}
                    freeSolo
                    id="firstName"
                    name="firstName"
                    // autoComplete="off"
                    onOpen={() => setOpenFirstName(true)}
                    onClose={() => setOpenFirstName(false)}
                    disableClearable
                    value={firstName}
                    options={firstNameOptions}
                    onChange={(e, option) => {
                      setFirstName(option);
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleSearchFirstName(newInputValue);
                      field.onChange(newInputValue);
                    }}
                    ListboxComponent="div"
                    ListboxProps={{ sx: ListBoxCustom }}
                    //PopperComponent={isMobile && "bottom"}
                    noOptionsText={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        label={firstName ? " " : ""}
                        size={isMobile ? "small" : "medium"}
                        sx={textFieldCustom}
                        className={
                          openFirstName && firstNameOptions.length > 0
                            ? "show"
                            : ""
                        }
                        InputLabelProps={{
                          shrink: false,
                          style: { ...textInputStyles, color: "#B7B7B7" }
                        }}
                        value={firstName}
                        error={!!errors?.firstName}
                        helperText={
                          errors?.firstName && errors?.firstName?.message
                        }
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            {/* firstName */}
            <Controller
              name="lastName"
              control={control}
              defaultValue={lastName}
              rules={{
                required: "Vui lòng điền họ!"
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    ...formControlCustom,
                    marginBottom: "7px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>Họ</FormHelperText>
                  <Autocomplete
                    {...field}
                    freeSolo
                    id="lastName"
                    name="lastName"
                    onOpen={() => setOpenLastName(true)}
                    onClose={() => setOpenLastName(false)}
                    disableClearable
                    value={lastName}
                    options={lastNameOptions}
                    onChange={(e, option) => {
                      setLastName(option);
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleSearchLastName(newInputValue);
                      field.onChange(newInputValue);
                    }}
                    ListboxComponent="div"
                    ListboxProps={{ sx: ListBoxCustom }}
                    //PopperComponent={isMobile && "bottom"}
                    noOptionsText={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        label={lastName ? " " : ""}
                        sx={textFieldCustom}
                        className={
                          openLastName && lastNameOptions.length > 0
                            ? "show"
                            : ""
                        }
                        InputLabelProps={{
                          shrink: false,
                          style: { ...textInputStyles, color: "#B7B7B7" }
                        }}
                        size={isMobile ? "small" : "medium"}
                        value={lastName}
                        error={!!errors?.lastName}
                        helperText={
                          errors?.lastName && errors?.lastName?.message
                        }
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            {/* lastName */}
            {/* date */}
            <FormHelperText sx={labelCustom}>
              Ngày tháng năm sinh
            </FormHelperText>
            <Box
              sx={{
                display: "flex",
                gap: "0",
                marginBottom: "13px"
              }}
            >
              {/* MONTH */}
              <Controller
                name="month"
                defaultValue={dob.month}
                control={control}
                rules={{ required: "Vui lòng chọn tháng sinh!" }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    error={!!errors?.month}
                    size={isMobile ? "small" : "medium"}
                    sx={{ ...formControlCustom }}
                    fullWidth
                  >
                    <Select
                      {...field}
                      inputRef={ref}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(value);
                        setDob((prev) => ({
                          ...prev,
                          month: value
                        }));
                      }}
                      value={dob.month}
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={{
                        ...selectCustom,
                        borderRadius: openMonthOptions
                          ? "8px 0 0 0"
                          : "8px 0 0 8px",
                        "& fieldset": {
                          borderRight: "none"
                        },
                        "& .MuiSelect-select": {
                          color: !dob.month && "#B7B7B7"
                        }
                      }}
                      onClose={() => {
                        setOpenMonthOptions(false);
                      }}
                      onOpen={() => {
                        setOpenMonthOptions(true);
                      }}
                      className={openMonthOptions ? "show" : ""}
                      renderValue={(selected) => {
                        return !selected ? "MM" : selected;
                      }}
                    >
                      {monthOptions.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.value}
                          sx={menuItemCustom}
                          size="small"
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors?.month && errors?.month.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              {/* END MONTH */}
              {/* DAY */}
              <Controller
                name="day"
                defaultValue={dob.day}
                control={control}
                rules={{ required: "Vui lòng chọn ngày sinh!" }}
                render={({ field }) => (
                  <FormControl
                    error={!!errors?.day}
                    size={isMobile ? "small" : "medium"}
                    sx={formControlCustom}
                    fullWidth
                    disabled={!dob.month}
                  >
                    <Select
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(value);
                        setDob((prev) => ({
                          ...prev,
                          day: value
                        }));
                      }}
                      value={dob.day}
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={{
                        ...selectCustom,
                        borderRadius: 0
                      }}
                      onClose={() => {
                        setOpenDayOptions(false);
                      }}
                      onOpen={() => {
                        setOpenDayOptions(true);
                      }}
                      className={openDayOptions ? "show" : ""}
                      renderValue={(selected) => {
                        return !selected ? "DD" : selected;
                      }}
                    >
                      {[...Array(Number(dob.totalDayInMonth)).keys()].map(
                        (number) => {
                          const dayValue = number + 1;
                          const formattedDay =
                            dayValue < 10
                              ? "0" + dayValue
                              : dayValue.toString();
                          return (
                            <MenuItem
                              key={number}
                              value={formattedDay}
                              sx={menuItemCustom}
                              size="small"
                            >
                              {formattedDay}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                    <FormHelperText>
                      {errors?.day && errors?.day.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              {/* END DAY */}
              {/* YEAR */}
              <Controller
                name="year"
                defaultValue={dob.year}
                control={control}
                rules={{ required: "Vui lòng chọn năm sinh!" }}
                render={({ field }) => (
                  <FormControl
                    error={!!errors?.year}
                    size={isMobile ? "small" : "medium"}
                    sx={formControlCustom}
                    fullWidth
                    disabled={!dob.day}
                  >
                    <Select
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(value);
                        setDob((prev) => ({
                          ...prev,
                          year: value
                        }));
                      }}
                      value={dob.year}
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={{
                        ...selectCustom,
                        borderRadius: openYearOptions
                          ? "0 8px 0 0"
                          : "0 8px 8px 0",
                        "& fieldset": {
                          borderLeft: "none"
                        }
                      }}
                      onClose={() => {
                        setOpenYearOptions(false);
                      }}
                      onOpen={() => {
                        setOpenYearOptions(true);
                      }}
                      className={openMonthOptions ? "show" : ""}
                      renderValue={(selected) => {
                        return !selected ? "YYYY" : selected;
                      }}
                    >
                      {[...Array(100).keys()].map((number) => (
                        <MenuItem
                          key={number}
                          value={2023 - number}
                          sx={menuItemCustom}
                          size="small"
                        >
                          {2023 - number}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors?.year && errors?.year.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              {/* END YEAR */}
            </Box>
            {errors?.age && (
              <FormHelperText
                error
                style={{
                  marginTop: "-15px",
                  marginBottom: "5px"
                }}
              >
                {errors?.age && errors?.age.message}
              </FormHelperText>
            )}
            {/* date */}
            <div className="group-btn">
              <PrevBtn to="/step1" />
              <LoadingButton
                endIcon={<SendIcon />}
                loading={loadingButton}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                style={btnSubmitCustom}
                onClick={() => {
                  handleSubmit(onSubmit);
                }}
                type="submit"
              >
                <span>Bước tiếp theo</span>
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step2;
