import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import "./Step10.scss";
import { currentCompanyDurationOptions } from "./options";
import Progress from "../../../components/Progress/Progress";
import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

const Step10 = () => {
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [currentCompanyDuration, setCurrentCompanyDuration] = useState(
    contactRedux?.currentCompanyDuration ?? ""
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 768;

  // custom
  const { handleSubmit } = useForm();
  const onSubmit = async () => {
    const res = await updateContact(dispatch, contactRedux._id, {
      currentCompanyDuration: currentCompanyDuration
    });

    if (res?.status === 200) {
      setTimeout(() => {
        navigate("/step11");
      }, 300);
    }
  };

  useEffect(() => {
    isMobile && window.scrollTo(0, 66);
  });

  return (
    <div className="step-10">
      <Navbar />
      <div className="container">
        <Progress step={10} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">
            Bạn đã làm việc cho công ty hiện tại bao lâu rồi?
          </h2>

          <div className="form-control">
            <div className="list-btn-radio">
              {currentCompanyDurationOptions.map((option, index) => (
                <button
                  key={index}
                  className={`btn-radio ${
                    currentCompanyDuration === option.value ? "active" : ""
                  }`}
                  onClick={() => {
                    setCurrentCompanyDuration(option.value);
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>

          <div className="group-btn">
            <PrevBtn className="btn-prev" to="/step8" />
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step10;
