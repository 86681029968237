import React from "react";
import "./HowItWork.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper/core";

import img1 from "../../assets/images/howItWork/img1.png";
import img2 from "../../assets/images/howItWork/img2.png";
import img3 from "../../assets/images/howItWork/img3.png";

const HowItWork = () => {
  SwiperCore.use([Pagination]);
  return (
    <div className="how-it-work" id="howItWork">
      <div className="container">
        <h2 className="title" data-aos="fade-left">
          Cách thức hoạt động
        </h2>
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          data-aos="fade-left"
          pagination={{ clickable: true }}
          breakpoints={{
            0: {
              // width: 576,
              slidesPerView: 1,
              dots: true,
              autoplay: { delay: 3000 }
            },
            1024: {
              // width: 768,
              slidesPerView: 3
            }
          }}
        >
          <SwiperSlide>
            <div className="card-image">
              <img src={img1} alt="" />
            </div>
            <div className="card-content">
              <h3 className="title">Hoàn thành mẫu đăng ký</h3>
              <p className="text">
                Toàn bộ quá trình cực kỳ đơn giản, tiết kiệm thời gian và an
                toàn khi gửi trực tuyến.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card-image">
              <img src={img2} alt="" />
            </div>
            <div className="card-content">
              <h3 className="title">Phản hồi nhanh</h3>
              <p className="text">
                Yêu cầu vay vốn của bạn sẽ được xem xét và phê duyệt nhanh chóng
                nếu bạn đáp ứng được tất cả các tiêu chí của người cho vay.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card-image">
              <img src={img3} alt="" />
            </div>
            <div className="card-content">
              <h3 className="title">Nhận tiền</h3>
              <p className="text">
                Tiền sẽ được chuyển vào tài khoản của bạn khi yêu cầu của bạn
                được người cho vay đồng ý
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HowItWork;
