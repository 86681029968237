import dayjs from "dayjs";

// // validationUtils.js
// export const validateInput = (contact) => {
//   switch (fieldName) {
//     case "amount":
//       if (!value) {
//         return "Please Select Amount";
//       }
//       break;
//     case "email":
//       if (!value) {
//         return "Please enter your email address";
//       }
//       if (!validateEmail(value)) {
//         return "Please enter a valid email address";
//       }
//       break;
//     default:
//       break;
//   }
//   return null; // No error
// };

// export const validateEmail = (email) => {
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return emailRegex.test(email);
// };
// CHECK AGE
export const checkAge = (year) => {
  const now = dayjs();

  const convertNow = dayjs(now).format("YYYY");
  const age = Number(convertNow) - Number(year);
  return age;
};
