import { publicRequest } from "../requestMethods";
import {
  addContactFailure,
  addContactStart,
  addContactSuccess,
  updateContactFailure,
  updateContactStart,
  updateContactSuccess
} from "./contactRedux";

// ADD CONTACT
export const addContact = async (dispatch, contact) => {
  dispatch(addContactStart());

  try {
    const res = await publicRequest.post("/contact", contact);
    if (res.data.status === 200) {
      const contactFromDB = res?.data?.contact;
      dispatch(addContactSuccess(contactFromDB));
    } else {
      dispatch(addContactFailure());
    }
    return res.data;
  } catch (err) {
    dispatch(addContactFailure());
  }
};

// UPADTE CONTACT
export const updateContact = async (dispatch, contactId, contact) => {
  dispatch(updateContactStart());

  try {
    const res = await publicRequest.put(`/contact/${contactId}`, contact);
    if (res.data.status === 200) {
      dispatch(updateContactSuccess(contact));
    } else {
      dispatch(updateContactFailure());
    }
    return res.data;
  } catch (err) {
    dispatch(updateContactFailure());
  }
};

// export const addContact = async (dispatch, contact) => {
//   dispatch(addContactStart());

//   try {
//     const res = await publicRequest.post("/contact", contact);
//     if (res.data.status === 200) {
//       dispatch(addContactSuccess(res.data.contact));
//     } else {
//       dispatch(addContactFailure());
//     }
//     return res.data;
//   } catch (err) {
//     dispatch(addContactFailure());
//   }
// };

// // UPADTE CONTACT
// export const updateContact = async (dispatch, contactId, contact) => {
//   dispatch(updateContactStart());

//   try {
//     const res = await publicRequest.put(`/contact/${contactId}`, contact);
//     if (res.data.status === 200) {
//       dispatch(updateContactSuccess(res.data.contact));
//     } else {
//       dispatch(updateContactFailure());
//     }
//     return res.data;
//   } catch (err) {
//     dispatch(updateContactFailure());
//   }
// };
