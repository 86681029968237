import React, { useRef, useEffect } from "react";
import "./RatesFee.scss";
import close from "../../../assets/images/close1.png";

export const RatesFee = ({ openRateFee, setOpenRateFee }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenRateFee(false);
      }
    };

    if (openRateFee) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openRateFee, setOpenRateFee]);

  return (
    <div className={`rates-free ${openRateFee ? "open" : ""}`}>
      <div className="box-popup">
        <div className="container">
          <div className="content-popup" ref={popupRef}>
            <h2 className="title">Giá và chi phí</h2>
            <p className="text">
              <b>Seekerloans.com</b> cung cấp dịch vụ miễn phí để giúp những
              người đi vay như bạn kết nối với những người cho vay đáng tin cậy
              để giải quyết các nhu cầu tài chính của bạn. Chúng tôi không xem
              xét, phê duyệt hoặc đưa ra bất kỳ quyết định nào liên quan đến yêu
              cầu hoặc phê duyệt khoản vay của bạn, bạn phải làm việc với người
              cho vay về lãi suất, APR, chính sách cho vay, phí hoặc bất kỳ quy
              tắc nào khác. Mỗi tiểu bang có thể có các quy định địa phương khác
              nhau để xử lý yêu cầu vay tiền của bạn, chúng tôi chỉ cố gắng hết
              sức để cung cấp cho bạn nhiều thông tin nhất có thể, bao gồm cách
              thức hoạt động và một số Câu hỏi thường gặp.{" "}
            </p>
            <p className="text">
              Điều quan trọng là phải đọc và hiểu tất cả thỏa thuận và thông tin
              liên quan, bao gồm phí và APR, cũng như các điều khoản trả nợ và
              các lựa chọn gia hạn khoản vay, nếu yêu cầu của bạn được người cho
              vay chấp thuận. Nếu có bất kỳ điều khoản hoặc điều kiện nào bạn
              cần làm rõ, vui lòng kiểm tra với người cho vay để giải quyết tất
              cả các câu hỏi, chúng tôi chỉ có thể giúp bạn với những câu hỏi
              liên quan đến dịch vụ của chúng tôi.{" "}
            </p>
            <p className="text">
              Điều quan trọng nhất mà bạn nên hiểu với tư cách là người đi vay
              là hàm ý của việc trả chậm khoản tín dụng của bạn. Mặc dù mỗi
              người cho vay mà chúng tôi hợp tác đều có các điều khoản và điều
              kiện riêng đối với khoản tạm ứng mà họ đưa ra, nhưng có một số hậu
              quả khi trả góp muộn mà bạn nên biết trước khi ký hợp đồng vay với
              người cho vay.
            </p>
            <p className="text">
              Seekerloans.com là một nền tảng miễn phí thu thập thông tin cá
              nhân của bạn để gửi cho người cho vay xem xét các yêu cầu vay tiền
              của bạn. Chúng tôi không liên quan đến quá trình xem xét hoặc giải
              quyết các câu hỏi liên quan đến khoản vay của bạn, vui lòng liên
              hệ trực tiếp với nhà cung cấp tài chính của bạn để làm rõ tất cả
              thông tin. Chúng tôi cố gắng hết sức để kết nối bạn với người cho
              vay phù hợp nhất, tuy nhiên quyết định phê duyệt sẽ hoàn toàn do
              người cho vay quyết định.{" "}
            </p>
            <p className="text">
              Bằng cách đọc Chính sách quyền riêng tư và Điều khoản sử dụng, bạn
              hiểu và đồng ý rằng Trang web này thu thập thông tin cá nhân và
              chia sẻ với các đối tác bên thứ ba. Ngoài ra, các liên kết của các
              trang web của bên thứ ba cũng được trình bày trên Trang web này.
              Vì bất kỳ lý do vay vốn nào, bạn nên kết nối với chuyên gia tư vấn
              trước khi quyết định khoản vay.
            </p>
          </div>
          <button
            className="close"
            onClick={() => setOpenRateFee(!openRateFee)}
          >
            <img src={close} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};
