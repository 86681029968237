import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step5.scss";
import Progress from "../../../components/Progress/Progress";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";
import SendIcon from "@mui/icons-material/Send";

import LoadingButton from "@mui/lab/LoadingButton";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  FormHelperText,
  TextField
} from "@mui/material";
import {
  btnSubmitCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";

import { trimWhiteSpaceObjValue } from "../../../utils/functions";

const Step5 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [zipCode, setZipCode] = useState(contactRedux?.zipCode ?? "");
  const [state, setState] = useState(contactRedux?.state ?? "");
  const [streetAddress, setStreetAddress] = useState(
    contactRedux?.streetAddress ?? ""
  );

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);

    const res = await updateContact(dispatch, contactRedux?._id, data);
    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/step6");
      }, 300);
    }
  };

  return (
    <div className="step-5">
      <Navbar />
      <div className="container">
        <Progress step={5} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Địa chỉ nhà bạn</h2>
          <div className="form-control">
            <FormHelperText sx={labelCustom}>Mã bưu chính</FormHelperText>
            <Controller
              name="zipCode"
              control={control}
              defaultValue={zipCode}
              rules={{
                required: "Zip code is required!",
                minLength: {
                  value: 4,
                  message: "Zip code must be 4 characters long!"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={(input) => {
                    input?.addEventListener("focus", () => {
                      input?.setAttribute("inputmode", "numeric");
                    });
                    ref(input);
                  }}
                  id="zipCode"
                  sx={{ ...textFieldCustom, marginBottom: "7px" }}
                  // autoComplete="zipCode"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.zipCode}
                  label={zipCode ? " " : ""}
                  helperText={errors?.zipCode && errors?.zipCode?.message}
                  inputProps={{
                    maxLength: 10
                  }}
                  InputLabelProps={{
                    shrink: false,
                    style: { ...textInputStyles, color: "#B7B7B7" }
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    field.onChange(value);
                    setZipCode(value);
                  }}
                  value={zipCode}
                  size={isMobile ? "small" : "medium"}
                />
              )}
            />
            {/* streetAddress */}
            <FormHelperText sx={{ ...labelCustom }}>
              Địa chỉ đường
            </FormHelperText>
            <Controller
              name="streetAddress"
              control={control}
              defaultValue={streetAddress}
              rules={{
                required: "Vui lòng nhập địa chỉ đường!"
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    {...field}
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="outlined"
                    fullWidth
                    type="text"
                    placeholder=""
                    sx={{ ...textFieldCustom, marginBottom: "7px" }}
                    error={!!error}
                    helperText={error?.message || ""}
                    onChange={(e) => {
                      field.onChange(e);
                      setStreetAddress(e.target.value);
                    }}
                  />
                );
              }}
            />
            {/* / streetAddress */}
            {/* city */}
            <FormHelperText sx={labelCustom}>Tỉnh/Thành phố</FormHelperText>
            <Controller
              name="state"
              control={control}
              defaultValue={state}
              rules={{
                required: "Vui lòng nhập tỉnh/thành phố!"
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    {...field}
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="outlined"
                    fullWidth
                    type="text"
                    placeholder=""
                    sx={{ ...textFieldCustom, marginBottom: "20px" }}
                    error={!!error}
                    helperText={error?.message || ""}
                    onChange={(e) => {
                      field.onChange(e);
                      setState(e.target.value);
                    }}
                  />
                );
              }}
            />
            {/* / city */}
            <div className="group-btn">
              <PrevBtn to="/step4" />
              <LoadingButton
                endIcon={<SendIcon />}
                loading={loadingButton}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                style={btnSubmitCustom}
                // onClick={() => {
                //   handleSubmit(onSubmit);
                // }}
                type="submit"
              >
                <span>Bước tiếp theo</span>
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step5;
