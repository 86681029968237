import React, { useRef, useEffect } from "react";
import "./FAQs.scss";
import close from "../../../assets/images/close1.png";

import bankImage from "../../../assets/images/FAQ/img1.png";

export const FAQs = ({ openFAQs, setOpenFAQs }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenFAQs(false);
      }
    };

    if (openFAQs) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openFAQs, setOpenFAQs]);

  return (
    <div className={`FAQs ${openFAQs ? "open" : ""}`}>
      <div className="box-popup">
        <div className="container">
          <div className="content-popup" ref={popupRef}>
            <h2 className="title">Câu hỏi thường gặp</h2>

            <h4>1. Seeker Loans là gì?</h4>
            <p>
              Người tìm kiếm cho vay tạm ứng tiền mặt và dịch vụ giới thiệu
              khoản vay cá nhân trực tuyến trên trang web của chúng tôi
            </p>
            <p>
              Nếu khách hàng đang tìm kiếm khoản tạm ứng tiền mặt hoặc khoản vay
              cá nhân trực tuyến, chúng tôi sẽ giúp kết nối họ với thị trường
              gồm các công ty cung cấp khoản vay cá nhân và ứng tiền mặt. Trang
              web của chúng tôi có một biểu mẫu an toàn yêu cầu thông tin mà
              người cho vay và/hoặc đối tác cho vay trong thị trường giới thiệu
              khoản vay cá nhân và tạm ứng tiền mặt của chúng tôi sẽ sử dụng để
              quyết định xem họ muốn cung cấp cho khách hàng một khoản tạm ứng
              tiền mặt hay khoản vay cá nhân. Thông tin họ cần để đưa ra quyết
              định tạm ứng tiền mặt hoặc cho vay cá nhân về từng khách hàng bao
              gồm tên, địa chỉ, số điện thoại nhà và nơi làm việc cũng như địa
              chỉ email. Họ cũng cần ngày sinh và số an sinh xã hội của khách
              hàng để tuân thủ Đạo luật Yêu nước của Hoa Kỳ. Có thể liên hệ với
              nhà điều hành trang web này bằng thư tại Seeker Loans, 2803
              Philadelphia Pike, Suite B #1020, Claymont, DE 19703, United
              States hoặc qua email. Khách hàng có thể liên hệ với chúng tôi nếu
              họ muốn ngừng sử dụng dịch vụ giới thiệu khoản vay của chúng tôi
              hoặc muốn thay đổi tùy chọn liên lạc của mình. Mọi thắc mắc về
              khoản tạm ứng tiền mặt hoặc số tiền cho vay cá nhân phải được
              chuyển đến công ty mà khách hàng nhận được khoản tạm ứng tiền mặt
              hoặc khoản vay cá nhân của mình.
            </p>

            <h4>2. Khoản vay sẽ có giá bao nhiêu?</h4>
            <p>
              Chi phí của khoản vay và Tỷ lệ phần trăm hàng năm (APR) phụ thuộc
              vào một số yếu tố bao gồm nhưng không giới hạn ở lịch sử tín dụng,
              nguồn thu nhập ổn định và luật pháp tiểu bang. APR của bạn cũng
              phụ thuộc vào số tiền bạn muốn vay và tốc độ bạn muốn trả khoản
              vay. Các điều khoản của khoản vay của bạn được tiết lộ trong quá
              trình yêu cầu khoản vay khi bạn được chuyển đến hợp đồng cho vay
              của người cho vay và/hoặc đối tác cho vay. Thông tin này hoàn toàn
              được giữ kín giữa bạn và người cho vay và/hoặc đối tác cho vay của
              bạn. Không phải tất cả khách hàng đều đủ điều kiện vay vốn hoặc
              đáp ứng các tiêu chí để nhận được điều kiện tốt nhất hoặc lãi suất
              thấp nhất.
            </p>

            <h4>
              3. Khoản vay cá nhân hoặc tạm ứng tiền mặt có thể được cung cấp
              trong bao lâu?
            </h4>
            <p>
              Thời gian cần thiết để xử lý khoản vay và chuyển khoản hoặc tiền
              ACH vào tài khoản của một cá nhân khác nhau tùy theo từng công ty
              cho vay và cũng tùy thuộc vào tổ chức tài chính nơi khách hàng có
              tài khoản ngân hàng. Tuy nhiên, những cá nhân được phê duyệt
              thường có thể nhận được khoản vay ngay trong ngày làm việc tiếp
              theo sau khi được phê duyệt.
            </p>

            <h4>4. Tại sao bạn thu thập SSN và Chi tiết ngân hàng của tôi?</h4>
            <p>
              Mạng lưới người cho vay của chúng tôi sử dụng số SSN và chi tiết
              ngân hàng của bạn để giúp họ đưa ra quyết định về việc có nên gia
              hạn đề nghị cho vay hay không. Thông tin chi tiết về ngân hàng
              cũng giúp người cho vay mà bạn kết nối biết cách gửi tiền vào tài
              khoản của bạn. Các trang web của chúng tôi được mã hóa bằng công
              nghệ mã hóa được ngành công nhận để giúp giữ an toàn cho thông tin
              của bạn. Ngoài ra, người cho vay sử dụng SSN để giúp đảm bảo danh
              tính của người nộp đơn thực sự là người mà họ khai.
            </p>

            <h4>5. Bạn có phải là người cho vay?</h4>
            <p>
              Không, chúng tôi không phải là người cho vay. Chúng tôi làm việc
              với một số người cho vay để tạo nên một mạng lưới có thể giúp bạn
              tìm khoản vay. Sau khi gửi yêu cầu, nếu được chấp nhận, chúng tôi
              sẽ chuyển tiếp bạn đến trang web của người cho vay, nơi bạn có thể
              tìm hiểu thêm về đề nghị cho vay cụ thể của mình.
            </p>

            <h4>6. Nếu tôi có tín dụng xấu thì sao?</h4>
            <p>
              Chúng tôi cố gắng kết nối người tiêu dùng với người cho vay bất kể
              lịch sử tín dụng của bạn như thế nào. Các yêu cầu cần thiết để phê
              duyệt khoản vay tùy thuộc vào từng cá nhân người cho vay. Vui lòng
              xem lại trang Cách thức hoạt động của chúng tôi để xác định xem
              bạn có đáp ứng các yêu cầu cơ bản để đủ điều kiện hay không.
            </p>

            <h4>
              7. Tôi có thể tìm ABA/Số định tuyến và số tài khoản của mình ở
              đâu?
            </h4>
            <p>
              Vui lòng xem sơ đồ bên dưới để biết hướng dẫn về cách tìm thông
              tin từ sổ séc của bạn. Ngoài ra, vui lòng gọi cho ngân hàng của
              bạn để biết thêm thông tin.
            </p>

            <div
              style={{
                textAlign: "center"
              }}
            >
              <img src={bankImage} alt="" />
            </div>

            <h4>
              8. Điều gì xảy ra nếu tôi không thanh toán (Ngụ ý về việc không
              thanh toán)?
            </h4>
            <p>
              Thanh toán trễ/một phần: Nếu bạn không thanh toán toàn bộ số tiền
              gốc và lãi trước ngày thanh toán đã thỏa thuận, bạn có thể sẽ bị
              tính một khoản phí tài chính mới và ngày thanh toán có thể được
              gia hạn cho đến ngày thanh toán tiếp theo của bạn. Khoản phí tài
              chính mới này có thể là một khoản phí cố định hoặc có thể được
              tính bằng cách áp dụng số tiền tương ứng của khoản phí tài chính
              cho số tiền vay chưa thanh toán. Xem lại chính sách thanh toán
              chậm được nêu chi tiết trong các tài liệu cho vay do người cho vay
              và/hoặc đối tác cho vay cung cấp cho bạn trước khi ký chữ ký điện
              tử của bạn và từ đó đồng ý với các điều khoản cho vay.
            </p>

            <p>
              Không thanh toán: Khoản vay cá nhân ngày hôm sau không bắt buộc
              thanh toán. Nếu bạn không trả tiền, người cho vay và/hoặc đối tác
              cho vay của bạn có thể khởi kiện bạn. Các khoản phí bổ sung có thể
              áp dụng cho bạn trong trường hợp bạn không thể trả được khoản vay
              của mình. Thông tin liên quan đến lịch sử thanh toán, tình trạng
              quá hạn hoặc không trả được nợ trên tài khoản của bạn có thể được
              báo cáo cho cơ quan báo cáo người tiêu dùng bên thứ ba và có thể
              ảnh hưởng đến xếp hạng tín dụng của bạn.
            </p>

            <p>
              Gia hạn khoản vay: Khoản vay cá nhân vào ngày hôm sau sẽ không gia
              hạn cho bạn. Người cho vay và/hoặc đối tác cho vay của bạn có các
              chính sách gia hạn sẽ khác nhau. Với mỗi lần gia hạn hoặc gia hạn,
              người cho vay và/hoặc đối tác cho vay có thể đánh giá một khoản
              phí tài chính mới ("Phí gia hạn") và ngày thanh toán lại có thể
              được gia hạn cho đến ngày thanh toán tiếp theo của người vay. Phí
              tài chính có thể rất lớn tùy thuộc vào người cho vay và/hoặc đối
              tác cho vay.
            </p>
            <p>
              Phương thức thu nợ: Phương thức thu nợ của người cho vay và/hoặc
              đối tác cho vay và/hoặc bất kỳ người được chuyển nhượng nào sẽ
              tuân theo các nguyên tắc của các quy định hiện hành của liên bang.
              Người cho vay và/hoặc đối tác cho vay có thể cố gắng liên hệ với
              người vay thông qua một hoặc nhiều phương thức được ủy quyền, bao
              gồm gọi điện thoại và email, để sắp xếp thanh toán. Tác động đến
              Điểm Tín dụng: Người cho vay và/hoặc đối tác cho vay của bạn có
              thể báo cáo các khoản thanh toán trễ hoặc thiếu cho cơ quan tín
              dụng. Điều này có thể ảnh hưởng tiêu cực đến điểm tín dụng của
              bạn. Người tiêu dùng gặp khó khăn về tín dụng nên tìm kiếm tư vấn
              tín dụng.
            </p>
            <h4>9. Khoản vay cá nhân hoặc khoản vay trả góp là gì?</h4>
            <p>
              Các khoản vay cá nhân và/hoặc trả góp thường dao động trong khoảng
              từ 100 đến 20.000 USD. Chúng có thể được sử dụng cho hầu hết mọi
              chi phí cá nhân, từ thanh toán thẻ tín dụng và hóa đơn y tế đến
              tài trợ cho việc sửa nhà hoặc thậm chí là một kỳ nghỉ rất cần
              thiết. Các khoản vay cá nhân thường được gọi là khoản vay trả góp
              vì chúng thường được trả dần hàng tháng hoặc hai tháng một lần
              trong một khoảng thời gian dài. Chúng có thể là giải pháp thay thế
              thuận tiện cho các khoản vay ngân hàng hoặc thẻ tín dụng lãi suất
              cao, với các mẫu yêu cầu vay trực tuyến và hoàn trả tự động không
              rắc rối.
            </p>
            <h4>10. Tôi có thể nhận được bao nhiêu?</h4>
            <p>
              Số tiền cho vay đủ điều kiện có thể khác nhau và dựa trên khả năng
              hội đủ điều kiện của cá nhân. Số tiền cho vay được phê duyệt có
              thể dao động từ 100 đến 20.000 USD.
            </p>
            <h4>11. Chi phí là bao nhiêu?</h4>
            <p>
              Không có chi phí để sử dụng getpaydayloans.com. Lệ phí của khoản
              vay sẽ khác nhau tùy thuộc vào số tiền và người cho vay. Người cho
              vay sẽ thông báo cho bạn về khoản phí cho khoản vay có thể thay
              đổi tùy theo người cho vay.
            </p>
            <h4>12. Chính sách gia hạn khoản vay</h4>
            <p>
              Các lựa chọn gia hạn khoản vay không phải lúc nào cũng có sẵn. Do
              đó, nên làm rõ liệu người cho vay của bạn có sẵn lựa chọn này hay
              không. Hãy chắc chắn đọc kỹ chính sách gia hạn được trình bày
              trong thỏa thuận trước khi bạn ký vào các tài liệu.
            </p>
            <h4>13. Ví dụ đại diện về APR</h4>
            <p>
              Nếu bạn vay 2.500 đô la trong thời hạn 1 năm với APR là 10% và phí
              3%, bạn sẽ phải trả 219,79 đô la mỗi tháng. Tổng số tiền phải trả
              sẽ là $2,637, với tổng tiền lãi là $137,48.
            </p>
            <h4>14. Thực hành thu thập</h4>
            <p>
              Người tìm kiếm khoản vay không phải là người cho vay. Vì vậy,
              chúng tôi không tham gia vào bất kỳ hoạt động thu nợ nào và không
              thể thông báo cho bạn về bất kỳ hoạt động nào trong số đó. Người
              cho vay của bạn sẽ chỉ định các phương pháp thu nợ của họ trong
              hợp đồng cho vay của bạn. Nếu bạn có bất kỳ câu hỏi nào liên quan
              đến vấn đề này, vui lòng giải quyết chúng với người cho vay của
              bạn. Để biết thêm thông tin, hãy truy cập trang Cho vay có trách
              nhiệm của chúng tôi. Bản thân Seekerloans.com không liên quan đến
              việc thu nợ.
            </p>
          </div>
          <button className="close" onClick={() => setOpenFAQs(!openFAQs)}>
            <img src={close} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};
