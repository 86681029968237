import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/images/ft-logo.png";
import ssl from "../../assets/images/ssl.png";
import secure from "../../assets/images/secure.png";
import { Link as Scroll } from "react-scroll";

import "./Footer.scss";

const Footer = ({ desc = true }) => {
  const location = useLocation();
  const menuURL = location.pathname.split("/")[1];

  return (
    <footer
      className="footer"
      id="footer"
      style={{
        background:
          menuURL === ""
            ? "#3f3f3f"
            : "linear-gradient(90deg, #3c3c3c 46.45%, #a6a7a8 100%)"
      }}
    >
      <div className="container">
        <div className="footer-top">
          <div className="logo-and-certificate">
            <div className="logo">
              {menuURL === "" ? (
                <Scroll
                  className="menu-item"
                  to="navbar"
                  spy={true}
                  smooth={true}
                  duration={800}
                  offset={-120}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <img src={logo} alt="" />
                </Scroll>
              ) : (
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              )}
            </div>
            <div className="certificate">
              <div className="ssl">
                <img src={ssl} alt="" />
              </div>
              <div className="secure">
                <img src={secure} alt="" />
              </div>
            </div>
          </div>
          <div className="menu">
            {menuURL === "" ? (
              <Scroll
                className="item"
                to="navbar"
                spy={true}
                smooth={true}
                duration={800}
                offset={-120}
              >
                Trang chủ
              </Scroll>
            ) : (
              <Link to="/" className="item">
                Trang chủ
              </Link>
            )}
            <Link to="/privacy" className="item">
              Chính sách bảo mật
            </Link>
            <Link to="/disclaimer" className="item">
              Tuyên bố miễn trừ trách nhiệm
            </Link>
            <Link to="/contact-us" className="item">
              Liên hệ với chúng tôi
            </Link>
            <Link to="/terms-of-use" className="item">
              Điều khoản sử dụng
            </Link>
            <Link to="/do-not-sell-information" className="item">
              Không mua bán thông tin
            </Link>
            <Link to="/unsubscribe" className="item">
              Huỷ đăng ký
            </Link>
          </div>
        </div>

        <div className="footer-bottom">
          {desc && (
            <p className="desc">
              Người điều hành trang web này không phải là người cho vay, hoặc
              đại diện, môi giới, đại lý của bất kỳ người cho vay nào và chúng
              tôi không đưa ra bất kỳ quyết định cho vay hay tín dụng nào. Trang
              web này không phải là một đề nghị hoặc chào mời cho vay. Vai trò
              của trang web này là gửi thông tin bạn cung cấp cho người cho vay,
              người có toàn quyền xem xét và phê duyệt khoản vay. Bằng cách gửi
              thông tin của mình, bạn đồng ý cho phép những người cho vay tham
              gia xác minh thông tin của bạn và kiểm tra tín dụng của bạn. Không
              phải tất cả người cho vay đều có thể cung cấp số tiền tối đa được
              quảng cáo. Thời gian chuyển tiền có thể khác nhau giữa những người
              cho vay và có thể phụ thuộc vào tổ chức tài chính cá nhân của bạn.
              Trong một số trường hợp có thể fax được yêu cầu. Không phải tất cả
              những người cho vay trong hệ thống của chúng tôi đều hoạt động ở
              tất cả các tiểu bang của Hoa Kỳ và các tiểu bang được trang web
              này phục vụ có thể thay đổi theo thời gian mà không cần thông báo.
              Nếu bạn có bất kỳ câu hỏi nào về khoản vay của mình, vui lòng liên
              hệ trực tiếp với người cho vay của bạn. Các khoản vay tiền mặt
              ngắn hạn nhằm giải quyết nhu cầu tiền mặt trước mắt và không phải
              là giải pháp lâu dài cho các vấn đề tài chính. Cư dân của một số
              tiểu bang có thể không đủ điều kiện cho khoản vay tiền mặt ngắn
              hạn dựa trên yêu cầu của người cho vay.
            </p>
          )}
          <p className="copyright">
            © 2023 Seekerloans.com All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
